import React from 'react';
import { BsFileEarmarkText, BsFillFileEarmarkTextFill, BsPlayCircleFill } from 'react-icons/bs';
import { MdOutlineAudioFile } from 'react-icons/md';

import { useChatControls } from '@/contexts/chat-controls';
import { useUploadedFiles } from '@/store/hooks/use-uploaded-files';
import { UploadedFile } from '@/store/slices/uploaded-files-slice';
import {
    AspectRatio,
    Box,
    Button,
    Flex,
    Grid,
    Icon,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';

export function RecentUploadsModal() {
    const { recentUploadsModal, uploadControls, setOption } = useChatControls();
    const { files, removeAll } = useUploadedFiles();
    return (
        <Modal
            onOverlayClick={recentUploadsModal.onClose}
            isCentered
            isOpen={recentUploadsModal.isOpen}
            onClose={recentUploadsModal.onClose}
            size={'md'}
            scrollBehavior={'inside'}
        >
            <ModalOverlay />
            <ModalContent backgroundColor={'background-main'}>
                <ModalCloseButton />
                <ModalHeader>Recent Files</ModalHeader>
                <ModalBody alignItems={'center'} justifyContent={'center'} minHeight={'300px'}>
                    <Grid gridTemplateColumns={'repeat(3, 1fr)'} gridTemplateRows={'repeat(3, 1fr)'} gap={'6px'}>
                        {files.map((f) => (
                            <FilePreview
                                onClick={() => {
                                    uploadControls.useExisting(f);
                                    setOption({ retrievalDataset: undefined });
                                    recentUploadsModal.onClose();
                                }}
                                key={f.url}
                                {...f}
                            ></FilePreview>
                        ))}
                    </Grid>
                </ModalBody>
                <ModalFooter>
                    <Button
                        size={'sm'}
                        onClick={() => {
                            removeAll();
                            recentUploadsModal.onClose();
                        }}
                    >
                        Clear History
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

function FilePreview(props: UploadedFile & { onClick: () => void }) {
    return (
        <AspectRatio ratio={1}>
            <Button
                variant={'unstyled'}
                position={'relative'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                bg={'whiteAlpha.200'}
                onClick={props.onClick}
            >
                {props.type === 'image' && (
                    <Image
                        alt={'user uploaded image'}
                        width={'100%'}
                        height={'100%'}
                        objectFit={'cover'}
                        src={props.url}
                        position="absolute"
                        top={0}
                        left={0}
                        background={'whiteAlpha.600'}
                    />
                )}
                {props.type === 'video' && (
                    <>
                        <Image
                            as={'video'}
                            width={'100%'}
                            height={'100%'}
                            objectFit={'cover'}
                            src={props.url}
                            position="absolute"
                            top={0}
                            left={0}
                            background={'whiteAlpha.600'}
                        />
                        <Text color={'text-secondary'} zIndex={1} fontSize={'24px'}>
                            <BsPlayCircleFill />
                        </Text>
                    </>
                )}
                {(props.type === 'audio' || props.type === 'code_file') && (
                    <Box width={'100%'}>
                        <Icon viewBox={'0 0 36 36'} fontSize={'36px'}>
                            {props.type === 'audio' && <MdOutlineAudioFile />}
                            {props.type === 'code_file' && <BsFileEarmarkText />}
                        </Icon>
                        <Text
                            px={'14px'}
                            py={'8px'}
                            fontSize={'12px'}
                            width={'100%'}
                            textAlign={'center'}
                            textOverflow={'ellipsis'}
                            overflow={'hidden'}
                        >
                            {props.filename}
                        </Text>
                    </Box>
                )}
            </Button>
        </AspectRatio>
    );
}
