import Head from 'next/head';
import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

type LayoutProps = {
    children: React.ReactNode;
    pageTitle: string;
};

export function Layout(props: LayoutProps & React.ComponentProps<any>) {
    const { children, pageTitle } = props;

    return (
        <Box
            height={'var(--chakra-vh)'}
            width={'100%'}
            onDragEnter={props.onDragEnter}
            onDragOver={props.onDragOver}
            onDrop={props.onDrop}
            onDragLeave={props.onDragLeave}
            onMouseLeave={props.onMouseLeave}
            overflow={'hidden'}
        >
            <Head>
                <title>{pageTitle}</title>
                <meta property="og:title" content={pageTitle} key="title" />
            </Head>
            <Flex width={'100%'}>{children}</Flex>
        </Box>
    );
}
