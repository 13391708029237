export const mimeTypeToMediaType = {
    // Image MIME types supported by PIL
    'image/jpeg': 'image',
    'image/png': 'image',
    'image/gif': 'image',
    'image/tiff': 'image',
    'image/x-icon': 'image',
    'image/bmp': 'image',
    'image/webp': 'image',

    // Audio MIME types supported by soundfile
    'audio/wav': 'audio',
    'audio/wave': 'audio',
    'audio/x-wav': 'audio',
    'audio/flac': 'audio',
    'audio/x-flac': 'audio',
    'audio/ogg': 'audio',
    'audio/mp3': 'audio',
    'audio/mpeg': 'audio',

    // Video MIME types supported by decord
    'video/mp4': 'video',
    'video/avi': 'video',
    'video/x-msvideo': 'video',
    'video/mpeg': 'video',
    'video/webm': 'video',
    'video/quicktime': 'video',
} as Record<string, string>;

export const mediaMimeTypes = Object.keys(mimeTypeToMediaType);
export const codeInterpreterMimeTypes = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
];
