import { rekaFactory } from '@/api/reka';
import { useChatId } from '@/hooks/use-chat-id';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useChats } from '@/store/hooks/use-chat-store';
import { useErrorMessageStore } from '@/store/hooks/use-error-message-store';
import { Message } from '@/utils/types';

export const useSendFeedback = () => {
    const { updateChatMessage, saveChat } = useChats();
    const { setErrorMessageAction } = useErrorMessageStore();
    const { apiKey } = useAuthStore();
    const id = useChatId()!;

    const handleFeedback = async (intent: boolean, messageIndex: number, history: Message[]) => {
        let selectedConvo: Message[] = [];
        if (history && messageIndex !== undefined && messageIndex >= 0 && messageIndex < history.length) {
            if (history[messageIndex].type === 'model') {
                for (let i = messageIndex - 1; i >= 0; i--) {
                    if (history[i].type === 'human') {
                        selectedConvo.unshift(history[i]);
                        break;
                    }
                }
                selectedConvo.push(history[messageIndex]);
            }
        }

        try {
            const prev = history[messageIndex].thumbs_up;
            updateChatMessage(id, messageIndex, { thumbs_up: intent });
            const reka = await rekaFactory(apiKey);
            const { err } = await reka.postFeedback(intent, selectedConvo);
            if (!err) {
                saveChat(id);
            } else {
                updateChatMessage(id, messageIndex, { thumbs_up: prev });
                setErrorMessageAction({ currentId: id, message: 'Error sending feedback' });
            }
        } catch (error) {
            setErrorMessageAction({ currentId: id, message: 'Error sending feedback' });
        }
    };

    return {
        handleFeedback,
    };
};
