import fetch from 'isomorphic-unfetch';

import { EventBucket, IChatParams } from '@/api/reka';
import { encryptJson } from '@/utils/crypto';
import { compact } from '@/utils/helper';
import { Message } from '@/utils/types';
import { captureException } from '@sentry/nextjs';

import { SSE } from '../../lib/sse';

const PUBLIC_KEY = process.env.NEXT_PUBLIC_LOGGED_OUT_KEY!;

export class Bff {
    private apiKey: string | null;

    constructor(apiKey: string | null) {
        this.apiKey = apiKey;
    }

    generateTitle(conversation: Message[]) {
        return this.getResponse<{ title: string }>(this.postJson('/bff/generate_title', conversation));
    }

    getAdminChat(id: string) {
        return this.getResponse<any>(this.getJson(`/bff/debug_chat/${id}`));
    }

    async loggedOutChatStream({
        human,
        conversationHistory = [],
        modelName = 'reka-flash',
        mediaUrl,
        mediaType,
    }: Partial<IChatParams>) {
        const evtBucket = new EventBucket<Message>();
        const seed = await encryptJson(
            {
                human,
                timestamp: new Date().toISOString(),
            },
            PUBLIC_KEY,
        );
        const sse: any = new SSE('/bff/chat', {
            headers: {
                'Content-Type': 'application/json',
            },
            payload: JSON.stringify(
                compact({
                    conversation_history: [
                        ...conversationHistory,
                        {
                            type: 'human',
                            text: human,
                            media_url: mediaUrl,
                            media_type: mediaType,
                        },
                    ],
                    stream: true,
                    model_name: modelName,
                    random_seed: seed,
                }),
            ),
            method: 'POST',
        });

        sse.addEventListener('message', (event: { data: string }) => {
            if (!event.data) return;
            try {
                const response: Message = JSON.parse(event.data);
                evtBucket.push(response);

                if (!!response.finish_reason) {
                    evtBucket.close();
                }
            } catch (error: any) {
                console.error('Message event cannot be parsed', error, event);
                captureException(error, { level: 'error', extra: event });
            }
        });
        sse.addEventListener('error', (event: { data: string }) => {
            try {
                const { detail } = JSON.parse(event.data);
                evtBucket.push(new Error(detail));
            } catch {
                evtBucket.push(new Error(''));
            }
        });
        sse.addEventListener('readystatechange', (event: { readyState: number }) => {
            if (event.readyState === 2) {
                evtBucket.close();
            }
        });
        sse.stream();
        return evtBucket.iterator;
    }

    private postJson(url: string, body: Record<any, any>) {
        const headers: Record<any, any> = {
            'Content-Type': 'application/json',
        };
        if (this.apiKey) {
            headers['X-Api-Key'] = this.apiKey;
        }
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers,
        });
    }

    private getJson(url: string) {
        const headers: Record<any, any> = {
            'Content-Type': 'application/json',
        };
        if (this.apiKey) {
            headers['X-Api-Key'] = this.apiKey;
        }
        return fetch(url, {
            method: 'GET',
            headers,
        });
    }

    private async getResponse<T>(request: Promise<any>) {
        let response;
        let err: string | null = null;
        try {
            response = await request;
        } catch {
            err = 'error';
        }

        if (!response || err) {
            return { data: null, err: 'error' };
        }

        let data: T;

        try {
            data = await response.json();
        } catch (e) {
            return { data: null, err: 'error' };
        }

        return { data, err: null };
    }
}
