import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';

import { RekaLink } from '@/components/reka-link';
import { SettingsModal } from '@/components/settings-modal';
import { SignUpModal } from '@/components/sign-up-modal';
import { useChatControls } from '@/contexts/chat-controls';
import { useRouterRef } from '@/hooks/use-router-ref';
import { useChats } from '@/store/hooks/use-chat-store';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import { ChatList } from './chat-list';

export function LeftPanel() {
    return (
        <Box
            w={['100%', '100%', '100%', '100%', '30%']}
            display={['none', 'none', 'none', 'none', 'block']}
            height={'var(--chakra-vh)'}
            p={'24px'}
        >
            <Box h={'100%'} w={'85%'}>
                <SidePanel />
            </Box>
        </Box>
    );
}

export function SidePanel({ isMobile, onClose }: { isMobile?: boolean; onClose?: () => void }) {
    const { setInput } = useChatControls();
    const { newChatAction } = useChats();
    const { isOpen: isSettingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure();
    const getRouter = useRouterRef();
    const { isLoggedOutChat, modalProps, showSignupGate, clearLoggedOutChat } = useLoggedOutChat();
    const [showNotice, setShowNotice] = useState(isLoggedOutChat);
    const handleNewChat = useCallback(() => {
        if (isLoggedOutChat) {
            if (!showNotice) {
                return clearLoggedOutChat();
            }
            setShowNotice(false);
            return showSignupGate('saves');
        }
        const router = getRouter();
        setInput('');
        newChatAction();
        router.push('/chat');
        if (onClose) onClose();
    }, [getRouter, setInput, newChatAction, onClose, showNotice]);

    return (
        <Flex flexDir={'column'} height={'100%'}>
            {!isMobile && (
                <Box mb={'24px'}>
                    <RekaLink onClick={handleNewChat} href={'/chat'}></RekaLink>
                </Box>
            )}
            {!isLoggedOutChat && <ChatList handleNewChat={handleNewChat} onSelect={onClose} />}
            {isLoggedOutChat && (
                <Flex grow={1} flexDirection={'column'}>
                    <Text mb={3}>
                        Explore the latest multimodal language models built by Reka. Create an account or log in to save
                        conversations, upload media and more.
                    </Text>
                    <Button
                        as={'a'}
                        href="/bff/auth/signup"
                        rel="nofollow"
                        mb={3}
                        border="2px solid var(--Linear, #9747FF)"
                    >
                        Sign up
                    </Button>
                    <Text textAlign={'center'} py={2}>
                        Have an account?{' '}
                        <Button as={'a'} href="/bff/auth/login" rel="nofollow" variant={'link'}>
                            Log in
                        </Button>
                    </Text>
                </Flex>
            )}
            <Button size={'lg'} variant={'darkPurple'} onClick={handleNewChat} mb={'24px'}>
                New conversation
            </Button>
            <Flex justifyContent={'space-between'}>
                <Text color={'text-secondary'} textStyle={'body-16'}>
                    © 2024 Reka AI
                </Text>
                {!isLoggedOutChat && (
                    <Button
                        _hover={{
                            color: 'border-alt',
                        }}
                        color={'text-primary'}
                        textDecoration={'underline'}
                        textTransform={'uppercase'}
                        variant={'link'}
                        onClick={onSettingsOpen}
                    >
                        Settings
                    </Button>
                )}
                <SettingsModal isOpen={isSettingsOpen} onClose={onSettingsClose} closeParent={onClose} />
                {isLoggedOutChat && <SignUpModal {...modalProps} />}
            </Flex>
        </Flex>
    );
}
