import { SharedChat } from '@/utils/types';
import hashObject from 'object-hash';

export const getSharedChatId = (chat: SharedChat, messageIndex: number) => {
    const sharedChatMessages = messageIndex > 0 ? chat.history.slice(0, messageIndex + 1) : chat.history;
    return hashObject({
        id: chat.id,
        history: sharedChatMessages,
    });
}
