import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { ChatIdMessages } from '@/components/chat-id-messages';
import { MultiModalEmptyChat } from '@/components/empty-chat';
import { InputControls } from '@/components/input-controls';
import { useChatControls } from '@/contexts/chat-controls';
import { useChatId } from '@/hooks/use-chat-id';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useChats } from '@/store/hooks/use-chat-store';
import { Chat } from '@/utils/types';
import { Box, Text, useDisclosure } from '@chakra-ui/react';

import { MenuDrawer } from './menu-drawer';
import { MobileRightPanelNav } from './mobile-right-panel-nav';
import { RightPanelContainer } from './right-panel-container';

export function RightChatPanel({ chat }: { chat?: Chat | null }) {
    const router = useRouter();
    let id = useChatId();
    const { noFirebase } = useAuthStore();

    const { isOpen, onOpen, onClose } = useDisclosure();
    if (chat?.id) {
        id = chat.id;
    }

    return (
        <>
            <RightPanelContainer>
                <MobileRightPanelNav onOpen={onOpen} currentTitle={chat?.title} />
                <Box
                    as="main"
                    flex="1"
                    px={['8px', '16px']}
                    paddingTop={['0px', '24px']}
                    overflowY="auto"
                    position={'relative'}
                >
                    {id === null && router.isReady && <MultiModalEmptyChat />}
                    {id !== null && chat && <ChatIdMessages item={chat} />}
                </Box>
                <Box as="footer" p={['8px', '16px']}>
                    <InputControls />
                    <Text
                        whiteSpace={'nowrap'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        textStyle={'body-12'}
                        textAlign={'center'}
                        mt={['4px', '9px']}
                        color={'header-primary'}
                    >
                        Responses can be incorrect. Please verify the accuracy of crucial factual information.
                    </Text>
                    {noFirebase && (
                        <Text
                            color="red"
                            whiteSpace={'nowrap'}
                            overflow={'hidden'}
                            textOverflow={'ellipsis'}
                            textStyle={'body-12'}
                            textAlign={'center'}
                            mt={['4px', '9px']}
                        >
                            Cannot connect to firebase. You can still chat with Yasa but features like conversation
                            share and history sync are disabled. Refresh to check if this is a temporary situation.
                        </Text>
                    )}
                </Box>
            </RightPanelContainer>

            <MenuDrawer isOpen={isOpen} onClose={onClose} />
        </>
    );
}
