import React, { ReactNode, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Resolver } from 'react-hook-form/dist/types/resolvers';

import { CheckCircleIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Heading,
    Input,
    Select,
    Stack,
    StackDivider,
    Switch,
    Text,
} from '@chakra-ui/react';

export function Section({ children }: { children: ReactNode }) {
    return (
        <Box py={'16px'} px={'12px'} mb={'16px'} bg={'setting-section'} borderRadius={'8px'}>
            {children}
        </Box>
    );
}

export function Form({
    onSubmit,
    children,
    values,
    resolver,
}: {
    children: ReactNode;
    onSubmit: (data: any) => Promise<FormError | undefined | void> | FormError | undefined | void;
    values?: Record<string, any>;
    resolver?: Resolver<any>;
}) {
    const [successful, setSuccessful] = useState(false);
    const props = useForm({ resolver, criteriaMode: 'all', mode: 'onSubmit', values });
    useEffect(() => {
        if (props.formState.isDirty) setSuccessful(false);
    }, [props.formState.isDirty]);
    const formSubmit = async (data: any) => {
        try {
            const err = await onSubmit(data);
            if (!err) {
                setSuccessful(true);
                props.reset();
                return;
            }
            Object.entries(err.fields).forEach(([name, message]) => props.setError(name, { message }));
        } catch (error) {
            props.setError('root', { message: 'Unable to save' });
        }
    };

    return (
        // @ts-ignore
        <FormProvider {...props} successful={successful}>
            <Box as={'form'} width={'100%'} onSubmit={props.handleSubmit(formSubmit)}>
                {children}
            </Box>
        </FormProvider>
    );
}

export function FormToggle({
    children,
    name,
    value,
    onChange,
}: {
    children: ReactNode;
    name: string;
    value?: boolean;
    onChange: (val: Record<string, boolean>) => void;
}) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ [name]: e.target.checked });
    };
    return (
        <FormControl display="flex" justifyContent={'space-between'} alignItems="center" mb={'16px'}>
            <FormLabel fontSize={'md'} mb="6px">
                {children}
            </FormLabel>
            <Switch isChecked={value} size={'md'} name={name} onChange={handleChange} />
        </FormControl>
    );
}

export function FormField({
    name,
    children,
    type,
    required,
    valueAsNumber,
    placeholder,
}: {
    children: ReactNode;
    name: string;
    type: string;
    required?: boolean;
    placeholder?: string;
    valueAsNumber?: boolean;
}) {
    const { register, formState } = useFormContext();
    const showError = !!((formState.isSubmitted || formState.touchedFields[name]) && formState.errors[name]);
    return (
        <FormControl
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={showError ? '10px' : '16px'}
            isRequired={required}
            isInvalid={showError}
        >
            <Text as={'label'} fontSize={'12px'} textTransform={'uppercase'} fontWeight={700}>
                {children}
            </Text>
            <Box maxW={'300px'} flex={1}>
                <Input
                    w={'100%'}
                    placeholder={placeholder}
                    borderRadius={'6px'}
                    borderColor={'border-main'}
                    _focus={{ boxShadow: 'none' }}
                    {...register(name, { valueAsNumber })}
                    size={'md'}
                    type={type}
                />
                <FormErrorMessage fontSize={'0.8em'}>{formState.errors[name]?.message as string}</FormErrorMessage>
            </Box>
        </FormControl>
    );
}

export function FormFooter({ children }: { children: ReactNode }) {
    const { formState } = useFormContext();
    return (
        <Flex justifyContent={'flex-end'}>
            <HStack gap={'12px'}>{children}</HStack>
            <Text fontSize={'0.8em'} color={'red'}>
                {formState?.errors?.root && formState.errors.root.message}
            </Text>
        </Flex>
    );
}

export function FormSubmitButton({ children }: { children: string }) {
    // @ts-ignore
    const { formState, successful } = useFormContext();
    return (
        <Button
            textTransform={'none'}
            leftIcon={!formState.isSubmitting && successful ? <CheckCircleIcon /> : undefined}
            type={'submit'}
            isLoading={formState.isSubmitting}
            loadingText={children}
            variant={'darkPurple'}
            size={'md'}
            width={'auto'}
        >
            {children}
        </Button>
    );
}

export function FormResetButton({ children }: { children?: ReactNode }) {
    const { formState, reset } = useFormContext();
    return formState.isDirty ? (
        <Button onClick={() => reset()} textTransform={'none'} variant={'ghost'} size={'md'}>
            {children ?? 'reset'}
        </Button>
    ) : null;
}

export function FormResetDefaultButton({ children, onClick }: { children?: ReactNode; onClick: () => any }) {
    const { reset } = useFormContext();
    const handleClick = () => {
        onClick();
        // we need redux store to be updated before resetting form
        setTimeout(reset, 10);
    };
    return (
        <Button onClick={handleClick} textTransform={'none'} variant={'ghost'} size={'md'}>
            {children}
        </Button>
    );
}

export class FormError extends Error {
    public fields: Record<string, string>;

    constructor(fields: Record<string, string> & { root?: string } = {}) {
        super(Object.values(fields).join(', '));
        this.fields = fields;
    }
}

export function FormRow({ children, label }: { children: React.ReactNode; label: string }) {
    return (
        <FormControl>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Text as={'label'} fontSize={'12px'} textTransform={'uppercase'} fontWeight={700}>
                    {label}
                </Text>
                {children}
            </Flex>
        </FormControl>
    );
}
