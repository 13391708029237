import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { useRouterRef } from '@/hooks/use-router-ref';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';

export function useChatId() {
    const { isLoggedOutChat, chat } = useLoggedOutChat();
    const router = useRouter();
    if (isLoggedOutChat && chat) return chat.id;
    const { id } = router.query;
    if (!id) return null;
    if (Array.isArray(id)) return id[0];
    return id;
}

export function useChatIdGetter() {
    const getRouter = useRouterRef();
    return useCallback(() => {
        const router = getRouter();
        const { id } = router.query;
        if (!id) return null;
        if (Array.isArray(id)) return id[0];
    }, []);
}
