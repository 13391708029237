import autosize from 'autosize';
import React, { useEffect, useRef } from 'react';

import { removeEmojis } from '@/utils/helper';
import { Textarea } from '@chakra-ui/react';

interface IAutoResizableTextarea {
    value: string;
    minHeight?: string;
    maxHeight?: string;
    onChange: (value: string) => void;
    onSubmit: (value: string) => void;
    listening: boolean;
}

export function AutoResizableTextarea(props: IAutoResizableTextarea) {
    const { value, onChange, onSubmit, listening } = props;

    const ref = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        autosize(ref.current!);
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            autosize.destroy(ref.current!);
        };
    }, []);

    useEffect(() => {
        autosize.update(ref.current!);
    }, [value]);

    const handleChange = (event: any) => {
        // onChange(removeEmojis(event.target.value));
        onChange(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            onSubmit(value);
        }
    };

    return (
        <Textarea
            autoFocus
            flex={1}
            readOnly={listening}
            rows={1}
            ref={ref}
            value={value}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            maxHeight={['80px', '110px']}
            minHeight={'40px'}
            variant={'unstyled'}
            resize={'none'}
            placeholder={'Send a message'}
        />
    );
}
