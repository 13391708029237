import React, { useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';

import { Flex, Input } from '@chakra-ui/react';
import { ButtonGroup, IconButton } from '@chakra-ui/react';

type EditableTitleProps = {
    title: string;
    handleSave: (chatId: string, input: string) => void;
    onClose: () => void;
    chatId: string;
};

export function EditableTitle(props: EditableTitleProps) {
    const { title, handleSave, onClose, chatId } = props;
    const [input, setInput] = useState(title);

    const saveInput = () => {
        handleSave(chatId, input);
    };
    return (
        <Flex px={'12px'} justifyContent={'space-between'} alignItems={'center'}>
            <Input
                value={input}
                size="xs"
                fontSize={'16px'}
                onChange={(e) => setInput(e.target.value)}
                borderWidth={0}
            />

            <ButtonGroup justifyContent="center" size="xs" alignItems={'center'}>
                <IconButton
                    color={'#12E600'}
                    variant={'ghost'}
                    aria-label="check-icon"
                    icon={<FiCheck size={'24px'} />}
                    onClick={() => {
                        saveInput();
                        onClose();
                    }}
                    _hover={{
                        backgroundColor: 'transparent',
                    }}
                />

                <IconButton
                    variant={'ghost'}
                    aria-label="close-icon"
                    icon={<MdClose size={'24px'} />}
                    _hover={{
                        backgroundColor: 'transparent',
                    }}
                    onClick={onClose}
                />
            </ButtonGroup>
        </Flex>
    );
}
