// This hook keeps the container scrolled to the bottom
import { useEffect, useRef, useState } from 'react';

import { Chat } from '@/utils/types';

export function useStickyScroll(item: Chat) {
    const IDLE_DURATION = 500; // ms before considering scroll stationary
    const STICKY_THRESHOLD = 0.25; // % of container that is sticky
    const MAX_THRESHOLD = 150; // max px distance

    const [isSticking, setIsSticking] = useState(false);
    const scrollRef = useRef<{ direction: 'up' | 'down'; top: number; scrolledAt: number }>({
        direction: 'down',
        top: 0,
        scrolledAt: Date.now(),
    });
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!scrollContainerRef?.current) return;
        if (item.loading) {
            // This fires at the beginning of a message stream
            scrollRef.current.direction = 'down'; // reset to down so the scroll will start sticking
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        } else {
            setIsSticking(false);
        }
    }, [item.loading]);

    useEffect(() => {
        const el = scrollContainerRef?.current;
        if (!el) return;
        const isScrolling = IDLE_DURATION > Date.now() - scrollRef.current.scrolledAt;
        const withinProximity =
            el.scrollHeight - el.scrollTop - el.clientHeight <=
            Math.min(el.clientHeight * STICKY_THRESHOLD, MAX_THRESHOLD);
        if (scrollRef.current.direction === 'down' && !isScrolling && withinProximity) {
            el.scrollTop = el.scrollHeight;
            setIsSticking(true);
        } else setIsSticking(false);
    }, [item]);

    useEffect(() => {
        if (!scrollContainerRef.current) return;
        const scrollListener = () => {
            if (!scrollContainerRef.current) return;
            const down = scrollContainerRef.current.scrollTop >= scrollRef.current.top;
            scrollRef.current.direction = down ? 'down' : 'up';
            scrollRef.current.top = scrollContainerRef.current.scrollTop;
            scrollRef.current.scrolledAt = Date.now();
            setIsSticking(false);
        };
        let el = scrollContainerRef.current;
        el.addEventListener('scroll', scrollListener);
        return () => {
            el.removeEventListener('scroll', scrollListener);
        };
    }, []);
    return { isSticking, scrollContainerRef };
}
