import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@/lib/query-keys';
import { SharedChatResource } from '@/api/firestore';

export function useSharedChat({ id }: { id: string }) {
    return useQuery({
        queryKey: QueryKeys.chat.getSharedChat(id),
        queryFn: async () => {
            const { data, err } = await SharedChatResource.fetch(id);

            if (err) {
                throw new Error(err);
            }

            return data;
        },
        enabled: !!id,
    })
}
