import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';

import { ShareChatModal } from '@/components/share-chat-modal';
import { useChatIdGetter } from '@/hooks/use-chat-id';
import { useChats, useFetchAllChats } from '@/store/hooks/use-chat-store';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Flex,
    Heading,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    useDisclosure,
} from '@chakra-ui/react';

import { ChatListItems } from './chat-list-items';

type ChatListProps = {
    handleNewChat: () => void;
    onSelect?: () => void;
    closeParent?: () => void;
    handleShareOpen?: () => void;
};
export function ChatList(props: ChatListProps) {
    const { handleNewChat } = props;
    const { chats, deleteAll, deleteChat, updateChat, getState } = useChats();
    const getCurrentId = useChatIdGetter()!;
    const { isLoading } = useFetchAllChats();
    const router = useRouter();
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();
    const [shareChatId, setChatId] = useState('');

    const handleShareOpen = useCallback(
        (chatId: string) => {
            setChatId(chatId);
            onShareOpen();
        },
        [setChatId, onShareOpen],
    );

    const handleClearChat = useCallback(() => {
        router.push('/chat');
        deleteAll();
    }, [deleteAll, router]);

    const handleDeleteItem = useCallback(
        (chatId: string) => {
            deleteChat(chatId);
            if (chatId === getCurrentId()) {
                handleNewChat();
            }
        },
        [handleNewChat, deleteChat, getCurrentId],
    );

    const updateTitle = useCallback(
        (chatId: string, title: string) => {
            updateChat(chatId, { title });
        },
        [updateChat],
    );

    const getChatContent = useCallback(
        (chatId: string) => {
            const chats = getState();
            return chats.find((c) => c.id === chatId)?.history;
        },
        [getState],
    );

    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
        // eslint-disable-next-line react/jsx-no-undef
        <Flex flexDirection={'column'} flex={1} minHeight={0} paddingBottom={'12px'}>
            <Flex paddingY={'8px'} justifyContent={'space-between'} mb={'16px'}>
                <Heading
                    color={'header-primary'}
                    display={'flex'}
                    alignItems={'center'}
                    fontWeight={500}
                    fontSize={'18px'}
                    as={'h2'}
                >
                    Conversations{isLoading && <Spinner ml={'12px'} size={'sm'} />}
                </Heading>
                <Menu strategy="fixed">
                    <MenuButton
                        as={IconButton}
                        isRound
                        size={'sm'}
                        fontSize={'18px'}
                        icon={<FiMoreVertical />}
                        variant={'ghost'}
                        aria-label={'conversation actions'}
                    />

                    <MenuList>
                        <MenuItem onClick={onOpen}>Clear conversations</MenuItem>
                    </MenuList>
                </Menu>
                <ConfirmAction
                    isOpen={isOpen}
                    onClose={onClose}
                    onConfirm={handleClearChat}
                    title={'Clear conversation'}
                >
                    Are you sure you want to clear your conversation history? This action cannot be reversed.
                </ConfirmAction>
            </Flex>
            <ChatListItems
                chats={chats}
                updateTitle={updateTitle}
                handleDeleteItem={handleDeleteItem}
                getChatContent={getChatContent}
                handleShareOpen={handleShareOpen}
            />
            {isShareOpen && <ShareChatModal messageIndex={-1} chatId={shareChatId} isOpen={isShareOpen} onClose={onShareClose} />}
        </Flex>
    );
}

function ConfirmAction({
    isOpen,
    onClose,
    title,
    children,
    onConfirm,
}: {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
    onConfirm: () => void;
}) {
    const handleConfirm = () => {
        onClose();
        onConfirm();
    };
    const cancelRef = React.useRef<HTMLButtonElement>(null);
    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>{children}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="red" onClick={handleConfirm} ml={3}>
                            Confirm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}
