import fetch from 'isomorphic-unfetch';

import { store } from '@/store/store';

export const httpPost = async (url: string, data?: any, apiKey?: string) => {
    const apiKeyState = store.getState().auth.apiKey;
    const rekaApiKey = apiKey ?? apiKeyState;

    const headerOptions = {
        'Content-type': 'application/json',
        'X-Api-Key': rekaApiKey as string,
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headerOptions,
        });
        return response;
    } catch (error) {
        throw new Error('Error in httpPost');
    }
};

export const httpDocumentPost = async (url: string, data: any) => {
    const rekaApiKey = store.getState().auth.apiKey;

    const headerOptions = {
        'X-Api-Key': rekaApiKey ?? '',
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: headerOptions,
        });
        return response;
    } catch (error) {
        throw new Error('Error in httpPost');
    }
};

export const httpGet = async (url: string, data?: any, contentType?: any) => {
    const rekaApiKey = store.getState().auth.apiKey;

    const headerOptions = {
        'Content-Type': 'application/json',
        'X-Api-Key': rekaApiKey ?? '',
    };

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headerOptions,
        });
        return response;
    } catch (error) {
        throw new Error('Error in httpPost');
    }
};
