const stopSignals: Record<string, Set<() => void>> = {};

// This creates a polling method to ping if the request should be alive.
// if the request is killed the ping will be false. The polling method
// will self clean if you call it with `true`
export function getHeartbeat(chatId: string) {
    let alive = true;
    const stopFn = () => (alive = false);

    stopSignals[chatId] ??= new Set();
    stopSignals[chatId].add(stopFn);
    return (stop: boolean = false) => {
        if (stop) {
            alive = false;
            stopSignals[chatId]?.delete(stopFn);
        }
        return alive;
    };
}

// This will kill all requests created for a chatid
export function stopRequests(chatId: string) {
    if (stopSignals[chatId]) {
        stopSignals[chatId].forEach((kill) => kill());
        delete stopSignals[chatId];
    }
}
