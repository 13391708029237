import React, { ReactNode, useEffect, useState } from 'react';
import {
    BsArrowCounterclockwise,
    BsFileEarmarkFill,
    BsFiletypeDoc,
    BsLink45Deg,
    BsPlayCircleFill,
    BsX,
    BsXCircle,
} from 'react-icons/bs';

import { rekaFactory } from '@/api/reka';
import { useChatControls } from '@/contexts/chat-controls';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { FormControlProps } from '@chakra-ui/form-control/dist/form-control';
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    ModalProps,
    Select,
    Spinner,
    Switch,
    Tag,
    TagCloseButton,
    TagLabel,
    TagLeftIcon,
    Text,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';

export function ChatToggle({
    children,
    isChecked,
    onChange,
    fontSize = '14px',
    ...rest
}: FormControlProps & {
    isChecked: boolean;
}) {
    return (
        <FormControl display={'flex'} width={'auto'} alignItems="center" {...rest}>
            <FormLabel whiteSpace={'nowrap'} fontSize={fontSize} mb="0">
                {children}
            </FormLabel>
            <Switch isChecked={isChecked} onChange={onChange} size={'sm'} />
        </FormControl>
    );
}

export function ModelSelector({ value, onChange }: { value: string; onChange: (val: string) => void }) {
    const [availableModels, setAvailableModels] = useState<any>(['reka-flash']);
    const { setChatParamsAction } = useAppConfig();
    const { apiKey } = useAuthStore();
    useEffect(() => {
        (async () => {
            const reka = await rekaFactory(apiKey);
            const { data } = await reka.getAvailableModels();
            if (data) {
                setAvailableModels(data as string[]);
            }
        })();
    }, []);
    return (
        <FormControl mb={'16px'}>
            <FormLabel fontSize={'md'}>Model name</FormLabel>
            <Select
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                placeholder={'Select a model'}
                borderRadius={'6px'}
                borderColor={'border-main'}
                background={'white15'}
                _focus={{ borderColor: 'white', boxShadow: 'none' }}
                size={'md'}
                marginBottom={'12px'}
            >
                {availableModels.map((model: string[] | string) =>
                    Array.isArray(model) ? (
                        <option key={model[0]} value={model[0]}>
                            {model[0]}
                        </option>
                    ) : (
                        <option key={model} value={model}>
                            {model}
                        </option>
                    ),
                )}
            </Select>
            {availableModels.length < 6 && (
                <Alert status="warning" mb={'12px'} fontSize={'12px'}>
                    <AlertIcon />
                    You are unable to view all models because you need to use an api key that is linked to a @reka.ai
                    user. You also need to configure the app to use the api key by going to SETTINGS {'>'} Reka API key.
                </Alert>
            )}
            <Button onClick={() => setChatParamsAction({ modelName: value })} size={'sm'} variant={'solid'}>
                Copy to settings
            </Button>
            {Array.isArray(availableModels[0]) && (
                <Box mt={'12px'}>
                    {value && (
                        <p>
                            <strong>current:</strong> {getModelName(availableModels, value)}
                        </p>
                    )}
                    <p>
                        <strong>default:</strong> {getModelName(availableModels, 'default')}
                    </p>
                    <p>
                        <strong>default mmlm:</strong> {getModelName(availableModels, 'default_mmlm')}
                    </p>
                </Box>
            )}
        </FormControl>
    );
}

function getModelName(models: string[][], key: string) {
    const lookupTable = models.reduce((table: any, pair) => {
        table[pair[0]] = pair[1];
        return table;
    }, {});
    let modelUrl = lookupTable[key];
    if (!modelUrl) return '';
    try {
        let steps = 0;
        while (steps < 5 && !modelUrl.startsWith('http')) {
            steps++;
            if (modelUrl in lookupTable) {
                modelUrl = lookupTable[modelUrl];
            } else {
                return modelUrl;
            }
        }
        const subdirectories = new URL(modelUrl).pathname;
        const parts = subdirectories.split('/');
        return parts[1];
    } catch (e) {
        captureException(e);
        return '';
    }
}

export function DocumentTag({
    children,
    disabled,
    onRemove,
}: {
    children: React.ReactNode;
    disabled: boolean;
    onRemove: () => void;
}) {
    return (
        <Tag
            background={'background-main'}
            color={'text-primary'}
            maxWidth={['140', '240', 'auto']}
            padding={'4px 12px'}
            variant={'solid'}
            size={'xs'}
        >
            <TagLeftIcon boxSize="12px" as={BsFileEarmarkFill} />
            <TagLabel fontSize={'14px'}>{children}</TagLabel>
            {!disabled && <TagCloseButton onClick={onRemove} />}
        </Tag>
    );
}

export function MediaPreview(
    props: Omit<IMediaButton, 'borderRadius' | 'onLoad' | 'size'> & {
        filename?: string;
    },
) {
    const { uploadControls } = useChatControls();
    const [duration, setDuration] = useState<number | null>(null);
    useEffect(() => {
        if (duration && duration > 60) {
            props.onError('duration_limit');
            props.setErrorMsg('Max video length is 1 min');
        }
    }, [duration, props.isLoading]);
    return (
        <Removable onRemove={props.onRemove}>
            {props.mediaType === 'custom' && (
                <InputGroup width={'360px'} maxW={'100%'} background={'whiteAlpha.200'}>
                    <InputLeftElement pointerEvents="none">
                        <BsLink45Deg fontSize={'22px'} />
                    </InputLeftElement>
                    <Input
                        onBlur={(e) => uploadControls.useCustomUrl(e.target.value)}
                        type="text"
                        placeholder={'Type or paste the full URL'}
                        id={'custom_url_input'}
                    />
                </InputGroup>
            )}
            {props.mediaType === 'image' && <MediaButton {...props} mediaType={'image'} />}
            {props.mediaType === 'application' && <MediaButton {...props} mediaType={'application'} />}
            {props.mediaType !== 'image' && props.mediaType !== 'application' && props.mediaType !== 'custom' && (
                <Flex>
                    <MediaButton
                        {...props}
                        borderRadius={'8px 0 0 8px'}
                        onLoad={(e) => {
                            const el = e.target as HTMLVideoElement;
                            setDuration(el.duration);
                        }}
                        size={props.mediaType === 'audio' ? '60px' : '80px'}
                    />
                    <Box
                        px={'12px'}
                        py={props.mediaType === 'audio' ? '4px' : '12px'}
                        width={'120px'}
                        bg={'background-secondary'}
                        borderRadius={'0 8px 8px 0'}
                    >
                        <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                            {props.filename}
                        </Text>
                        {duration !== null && <Text color={'text-subtle'}>{formatSeconds(duration)}</Text>}
                    </Box>
                </Flex>
            )}
        </Removable>
    );
}

function formatSeconds(duration: number) {
    duration = Math.round(duration);
    const s = String(duration % 60).padStart(2, '0');
    const m = Math.floor(duration / 60);
    return `${m}:${s}`;
}

interface IMediaButton {
    src: string;
    borderRadius?: string;
    mediaType: 'audio' | 'video' | 'image' | 'application' | 'custom';
    isLoading?: boolean;
    error?: 'retry' | 'unsupported' | 'size_limit' | 'duration_limit' | null;
    onRetry: () => void;
    onRemove: () => void;
    onError: (msg: any) => void;
    setErrorMsg: (msg: any) => void;
    onLoad?: React.ReactEventHandler<HTMLElement>;
    size?: string;
}

const ERROR_MSG = {
    unsupported: 'Media not supported',
    retry: 'Re-upload',
    size_limit: 'Media too large',
    duration_limit: 'Media too long',
};

function MediaButton({
    src,
    onRetry,
    onRemove,
    isLoading = false,
    error,
    borderRadius = '8px',
    onLoad = (e) => {},
    mediaType,
    size = '80px',
}: IMediaButton) {
    const p = useDisclosure();
    const handleClick = () => {
        if (error === 'retry') {
            onRetry();
        } else if (error === 'unsupported') {
            onRemove();
        } else if (error === 'size_limit') {
            onRemove();
        } else if (error === 'duration_limit') {
            onRemove();
        } else if (!loadError) {
            p.onOpen();
        }
    };
    const errorMsg = error ? ERROR_MSG[error] : '';
    const [loadError, setLoadError] = useState(false);
    useEffect(() => {
        setLoadError(false);
    }, [src]);
    return (
        <>
            <Tooltip label={errorMsg || (loadError && 'preview unavailable')}>
                <Button
                    width={size}
                    height={size}
                    position={'relative'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    variant={'unstyled'}
                    cursor={'pointer'}
                    _hover={{
                        opacity: 0.9,
                    }}
                    _focus={{}}
                    _active={{
                        opacity: 0.8,
                    }}
                    borderRadius={borderRadius}
                    onClick={handleClick}
                >
                    {mediaType !== 'audio' && mediaType !== 'application' && (
                        <Image
                            as={mediaType === 'video' ? 'video' : 'img'}
                            alt={'user uploaded image'}
                            width={'100%'}
                            height={'100%'}
                            objectFit={'cover'}
                            src={src}
                            opacity={isLoading || error ? '0.3' : '1'}
                            position="absolute"
                            borderRadius={borderRadius}
                            top={0}
                            left={0}
                            background={'text-subtle'}
                            onError={() => setLoadError(true)}
                            onLoadedData={onLoad}
                        />
                    )}
                    {mediaType === 'audio' && (
                        <>
                            <Box
                                top={0}
                                left={0}
                                position="absolute"
                                width={'100%'}
                                height={'100%'}
                                borderRadius={'8px 0 0 8px'}
                                bg={isLoading ? 'text-subtle' : 'text-secondary'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                fontSize={'32px'}
                            >
                                <BsPlayCircleFill color={'black'} />
                            </Box>
                            <Box
                                as={'audio'}
                                width={'0'}
                                height={'0'}
                                src={src}
                                position="absolute"
                                top={0}
                                left={0}
                                onError={() => setLoadError(true)}
                                onLoadedData={onLoad}
                            />
                        </>
                    )}
                    {mediaType === 'application' && (
                        <Box
                            top={0}
                            left={0}
                            width={'100%'}
                            height={'100%'}
                            borderRadius={'8px'}
                            bg={isLoading ? 'text-subtle' : 'text-secondary'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            fontSize={'32px'}
                            position="absolute"
                        >
                            <BsFiletypeDoc color={'black'} />
                        </Box>
                    )}
                    {isLoading && !error && <Spinner size={'xl'} thickness={'6px'} />}
                    {['unsupported', 'size_limit', 'duration_limit'].includes(error || '') && (
                        <Box zIndex={1} fontSize={'32px'} color={'red.300'}>
                            <BsXCircle />
                        </Box>
                    )}
                    {error === 'retry' && (
                        <Box zIndex={1} fontSize={'32px'} color={'red.300'}>
                            <BsArrowCounterclockwise />
                        </Box>
                    )}
                </Button>
            </Tooltip>
            <PreviewModal isOpen={p.isOpen} onClose={p.onClose} onRemove={onRemove}>
                {mediaType === 'image' && (
                    <Image
                        alt={'uploaded image'}
                        width={'100%'}
                        height={'100%'}
                        objectFit={'contain'}
                        maxH={'600px'}
                        maxW={'600px'}
                        src={src}
                    />
                )}
                {mediaType === 'video' && (
                    <Image
                        alt={'uploaded video'}
                        as={'video'}
                        width={'100%'}
                        height={'100%'}
                        objectFit={'contain'}
                        maxH={'600px'}
                        maxW={'600px'}
                        src={src}
                        controls
                    />
                )}
                {mediaType === 'audio' && (
                    <Image alt={'uploaded audio'} as={'audio'} width={'100%'} maxW={'600px'} src={src} controls />
                )}
            </PreviewModal>
        </>
    );
}

function Removable({ children, onRemove }: { children: ReactNode; onRemove: () => void }) {
    return (
        <Box
            position={'relative'}
            sx={{
                '--show-btn': '0',
            }}
            _hover={{ '--show-btn': '1' }}
        >
            {children}
            <IconButton
                aria-label={'remove media'}
                position={'absolute'}
                top={'-8px'}
                right={'-8px'}
                icon={<BsX />}
                variant={'solid'}
                isRound
                fontSize={'18px'}
                size={'xs'}
                colorScheme={'red'}
                opacity={'var(--show-btn)'}
                onClick={onRemove}
            />
        </Box>
    );
}

function PreviewModal({
    isOpen,
    onClose,
    children,
    onRemove,
}: Pick<ModalProps, 'isOpen' | 'onClose' | 'children'> & {
    onRemove: () => void;
}) {
    return (
        <Modal
            onOverlayClick={onClose}
            isCentered
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            size={'md'}
        >
            <ModalOverlay />
            <ModalContent backgroundColor={'background-main'}>
                <ModalBody p={'18px 18px 0 18px'} alignItems={'center'} justifyContent={'center'}>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <Button size={'sm'} onClick={onRemove}>
                        Remove
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
