import React, { useRef, useState } from 'react';

export function useDragDetection() {
    const counterRef = useRef(0);
    const [hasDragging, setDragging] = useState(false);
    return {
        hasDragging,
        onDragEnter: (e: React.DragEvent) => {
            counterRef.current++;
            const type = e.dataTransfer.items[0]?.type;
            if (type && ['image', 'audio', 'video'].includes(type.split('/')[0])) {
                if (!hasDragging) setDragging(true);
            }
        },
        onDragOver: (e: React.DragEvent) => {
            e.preventDefault();
        },
        onDrop: (e: React.DragEvent) => {
            e.preventDefault();
            counterRef.current = 0;
            setDragging(false);
        },
        onDragLeave: () => {
            if (--counterRef.current <= 0) {
                counterRef.current = 0;
                setDragging(false);
            }
        },
        onMouseLeave: () => {
            counterRef.current = 0;
            setDragging(false);
        },
    };
}
