import { useState } from 'react';

import { ChatMetadata } from '@/api/firestore';
import { rekaFactory } from '@/api/reka';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useChats } from '@/store/hooks/use-chat-store';
import { useErrorMessageStore } from '@/store/hooks/use-error-message-store';
import { sliceContext } from '@/utils/helper';
import { getHeartbeat, stopRequests } from '@/utils/stop-coordinator';
import { Chat, Message } from '@/utils/types';
import { captureMessage } from '@sentry/nextjs';

export function useResendMessage(chatId: string) {
    const { updateChat, chats, updateChatMessage, updateChatLoading, saveChat } = useChats();
    const { setErrorMessageAction } = useErrorMessageStore();
    const { apiKey, appUser } = useAuthStore();
    const [resendCount, setResendCount] = useState(1);
    const { chatParameters, allPersonas } = useAppConfig();

    const handleReSend = async (messageId: number = 0, message?: string) => {
        setResendCount(resendCount + 1);
        const currentChat = chats.find((item: Chat) => item?.id === chatId);

        if (!currentChat || !currentChat.history) return;

        const history = currentChat.history.slice(0, messageId);
        const lastPrompt: Message = currentChat.history.at(messageId)!;
        const prevResponse = currentChat.history[messageId + 1];
        updateChat(chatId, { history: currentChat.history.slice(0, messageId + 1) });

        const newParams = {
            human: message ?? lastPrompt.text!,
            randomSeed: chatParameters.randomSeed && chatParameters.randomSeed + resendCount,
            conversationHistory: sliceContext(history),
            ...chatParameters,
            mediaUrl: lastPrompt.image_url,
            mediaType: lastPrompt.media_type,
            fileUrl: lastPrompt.file_url,
            useCodeInterpreter: lastPrompt.use_code_interpreter,
            useSearchEngine: lastPrompt.use_search_engine,
            modelName: currentChat.modelName || chatParameters.modelName || 'reka-flash',
            retrievalDataset: currentChat.retrievalDataset,
            persona: currentChat.persona
                ? allPersonas.find((entry) => entry.name === currentChat.persona)?.persona
                : undefined,
        };

        setErrorMessageAction({ currentId: chatId, message: '' });

        const ping = getHeartbeat(chatId);
        try {
            const reka = await rekaFactory(apiKey);
            updateChatLoading(chatId, true);
            const iterator = reka.chatStream(newParams);
            updateChatMessage(chatId, messageId, {
                type: 'human',
                text: message ?? lastPrompt.text!,
            });
            updateChatMessage(chatId, messageId + 1, {
                type: 'model',
                text: '',
                clear_context: prevResponse && prevResponse.clear_context ? false : prevResponse?.clear_context,
            });

            let response;
            for await (response of iterator) {
                if (!ping()) {
                    reka.stop();
                    break;
                }
                updateChatMessage(chatId, messageId + 1, response);
            }
            if (appUser?.uid) {
                if (message) {
                    ChatMetadata.registerEvent(
                        appUser?.uid,
                        chatId,
                        {
                            name: 'edit_prompt',
                            previousResponse: prevResponse?.text,
                            nextResponse: response?.text ?? '',
                            previousPrompt: lastPrompt.text,
                            nextPrompt: message,
                            messageIndex: messageId,
                        },
                        history, // excluding the last turn
                    );
                } else {
                    ChatMetadata.registerEvent(
                        appUser?.uid,
                        chatId,
                        {
                            name: 'regenerate',
                            previous: prevResponse?.text,
                            next: response?.text ?? '',
                            prompt: lastPrompt.text,
                            messageIndex: messageId,
                        },
                        history, // excluding the last turn
                    );
                }
            }
        } catch (error) {
            captureMessage('chat api error', {
                user: {
                    username: appUser?.uid,
                },
                extra: {
                    error,
                    model: newParams.modelName,
                    prompt: newParams.human,
                },
                level: 'error',
            });
            console.error(error);
            console.log('SSE error', { error });
            let errorMsg = 'Network error';
            if (error instanceof Error) {
                errorMsg = error.message;
            } else if (typeof error === 'string') {
                errorMsg = error;
            }
            setErrorMessageAction({
                currentId: chatId,
                message: errorMsg,
            });
        }
        ping(true);
        updateChatLoading(chatId, false);
        saveChat(chatId);
    };

    return {
        handleReSend,
    };
}
