import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';

const MODAL_COPY = {
    feature: {
        header: 'Access all of Reka',
        body: 'Sign up to experience the full capabilities of our models. Chat using images, videos or pdf documents with an account.',
    },
    models: {
        header: 'Try our other models',
        body: 'Sign up to explore all our models and to chat using images, videos or pdf documents.',
    },
    saves: {
        header: 'Keep your chat history',
        body: 'Your current conversation will no longer be accessible. Sign up or login to save your next conversation.',
    },
};

export function SignUpModal({
    isOpen,
    onClose,
    modalType,
}: {
    isOpen: boolean;
    onClose: () => void;
    modalType: 'feature' | 'saves' | 'models' | null;
}) {
    if (modalType === null) return null;
    const { header, body } = MODAL_COPY[modalType] || MODAL_COPY['feature'];
    return (
        <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={'24px'} fontWeight={'500'} color={'header-secondary'}>
                    {header}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody mb={'16px'} color={'text-secondary'}>
                    {body}
                </ModalBody>

                <ModalFooter borderTop={'1px solid'} border={'header-underline'}>
                    {modalType === 'saves' && (
                        <Button mr={'auto'} onClick={onClose}>
                            Continue
                        </Button>
                    )}
                    <Button as={'a'} href="/bff/auth/login" rel="nofollow" mr={3}>
                        Log In
                    </Button>
                    <Button as={'a'} href="/bff/auth/signup" rel="nofollow" width={'auto'} variant={'darkPurple'}>
                        Sign Up
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
