import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import {
    addChatMessage,
    clearChat,
    setChat,
    setShowModal,
    updateChat,
    updateChatResponse,
} from '@/store/slices/logged-out-chat-slice';
import { RootState } from '@/store/store';

export function useLoggedOutChat() {
    const dispatch = useDispatch();
    const { chat, showModalType } = useSelector((state: RootState) => state.loggedOutChatSlice);
    const router = useRouter();
    const showSignupGate = (gateType: 'feature' | 'saves' | 'models') => {
        dispatch(setShowModal(gateType));
    };

    function withDispatch<F extends (...args: any[]) => any>(fn: F) {
        return (...payload: Parameters<F>) => dispatch(fn(...payload));
    }
    return {
        isLoggedOutChat: router.pathname === '/',
        showSignupGate,
        modalProps: {
            isOpen: showModalType !== null,
            modalType: showModalType,
            onClose: withDispatch(() => setShowModal(null)),
        },
        chat,
        clearLoggedOutChat: withDispatch(clearChat),
        setLoggedOutChat: withDispatch(setChat),
        updateLoggedOutChatResponse: withDispatch(updateChatResponse),
        updateLoggedOutChat: withDispatch(updateChat),
        addLoggedOutChatMessage: withDispatch(addChatMessage),
    };
}
