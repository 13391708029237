import { useState } from 'react';

import { Box, Button, HStack, Text } from '@chakra-ui/react';

export function ErrorMessageContainer({
    errorMessage,
    handleRetry,
}: {
    errorMessage?: string;
    handleRetry: () => void;
}) {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <HStack
            borderLeftWidth={1}
            borderRightWidth={1}
            borderTopWidth={1}
            borderBottomWidth={1}
            borderColor={'red100'}
            py={['12px', '24px']}
            px={['14px', '26px']}
            alignItems={'center'}
            flexDir={'row'}
            justifyContent={'space-between'}
            mt={'16px'}
        >
            <Box w={'70%'}>
                <Text textStyle={'body-18'} color={'text-primary'} lineHeight={'26px'}>
                    We encountered an error with your request.
                    {!showDetails && (
                        <Button
                            mr={'4px'}
                            onClick={() => setShowDetails(true)}
                            variant={'unstyled'}
                            textDecoration={showDetails ? '' : 'underline'}
                            cursor={showDetails ? 'default' : undefined}
                        >
                            Click here for more details
                        </Button>
                    )}
                </Text>
                {showDetails && (
                    <Text textStyle={'body-18'} color={'text-primary'} lineHeight={'26px'}>
                        <b>Error details:</b> {errorMessage}
                    </Text>
                )}
            </Box>

            <Box as={'button'} onClick={() => handleRetry()} w={'30%'}>
                <Text textAlign={'end'} textStyle={'body-18'} color={'text-primary'} textTransform={'uppercase'}>
                    Try again
                </Text>
            </Box>
        </HStack>
    );
}
