import React from 'react';

import { ChatToggle, ModelSelector } from '@/components/input-controls/components';
import { useChatControls } from '@/contexts/chat-controls';
import { useAppConfig } from '@/store/hooks/use-app-config';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Tooltip,
} from '@chakra-ui/react';

export function OptionsDrawerComponent(props: {
    drawer: {
        personaSelector: boolean | undefined;
        modelSelector: boolean | undefined;
        codeInterpreter: boolean;
    };
}) {
    const { optionsModal, requestOption, setOption } = useChatControls();
    const { allPersonas } = useAppConfig();
    const allPersonaNames = [...allPersonas.map((persona) => persona.name)];
    return (
        <OptionsDrawer onClose={optionsModal.onClose} isOpen={optionsModal.isOpen}>
            {props.drawer.codeInterpreter && (
                <ChatToggle
                    fontSize={'16px'}
                    isChecked={requestOption.useCodeInterpreter}
                    onChange={() => setOption({ useCodeInterpreter: !requestOption.useCodeInterpreter })}
                >
                    Code interpreter
                </ChatToggle>
            )}
            {props.drawer.modelSelector && (
                <ModelSelector
                    value={requestOption.modelName}
                    onChange={(val: string) => setOption({ modelName: val })}
                />
            )}
            {props.drawer.personaSelector && (
                <FormControl mb={'16px'}>
                    <Tooltip label="To customize personas, use the settings panel">
                        <FormLabel fontSize={'md'}>Custom persona</FormLabel>
                    </Tooltip>
                    <Select
                        borderRadius={'6px'}
                        borderColor={'border-main'}
                        background={'white15'}
                        _focus={{ borderColor: 'white', boxShadow: 'none' }}
                        size={'md'}
                        value={requestOption.persona}
                        placeholder={'reka-flash'}
                        onChange={(e) => setOption({ persona: e.target.value })}
                    >
                        {allPersonaNames.map((persona) => (
                            <option key={persona} value={persona}>
                                {persona}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            )}
        </OptionsDrawer>
    );
}

function OptionsDrawer({
    children,
    onClose,
    isOpen,
}: {
    children: React.ReactNode;
    onClose: () => void;
    isOpen: boolean;
}) {
    return (
        <Drawer onClose={onClose} isOpen={isOpen} size={'xs'}>
            <DrawerOverlay />
            <DrawerContent background={'modal-background'}>
                <DrawerCloseButton />
                <DrawerHeader as={'h1'} fontSize={'24px'}>
                    Conversation options
                </DrawerHeader>
                <DrawerBody>
                    <Flex gap={'16px'} flexDirection={'column'} alignItems={'flex-start'}>
                        {children}
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}
