import mime from 'mime-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { BiImageAdd } from 'react-icons/bi';
import {
    BsCameraVideo,
    BsClockHistory,
    BsDatabase,
    BsDatabaseAdd,
    BsFiletypeDoc,
    BsFillFileEarmarkRuledFill,
    BsFillMicFill,
    BsImage,
    BsLink45Deg,
    BsSliders2,
    BsSoundwave,
    BsTrashFill,
} from 'react-icons/bs';
import { MdSend } from 'react-icons/md';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { AutoResizableTextarea } from '@/components/auto-resizable-text-area';
import cl from '@/components/components.module.css';
import { ChatToggle, DocumentTag, MediaPreview } from '@/components/input-controls/components';
import { DatasetsDrawer } from '@/components/input-controls/dataset-drawer';
import { OptionsDrawerComponent } from '@/components/input-controls/options-drawer';
import { RecentUploadsModal } from '@/components/input-controls/recent-uploads-modal';
import { configValue } from '@/config/config-value';
import { getFileType, getMediaType, useChatControls } from '@/contexts/chat-controls';
import { useUserAuth } from '@/contexts/user-authentication';
import { useChatId } from '@/hooks/use-chat-id';
import { useSendMessage } from '@/hooks/use-send-message';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useChats } from '@/store/hooks/use-chat-store';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';
import { useUploadedFiles } from '@/store/hooks/use-uploaded-files';
import { IAppConfigState } from '@/store/slices/app-config-slice';
import { IRequestOptionState } from '@/store/slices/request-option-slice';
import { getChatHistory, lastContextLength } from '@/utils/helper';
import {
    Box,
    Divider,
    Flex,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
    Tooltip,
    useBreakpoint,
    useDisclosure,
} from '@chakra-ui/react';

import { mediaMimeTypes } from './constants';

export function InputControls() {
    const { handleMessageSend } = useSendMessage();
    const breakpoint = useBreakpoint({ fallback: 'md' });

    const [isListening, setListening] = useState(false);
    const id = useChatId();
    const { enabledFeatures } = useAppConfig();
    const { uploadControls, optionsModal, requestOption, setOption, input, setInput, showDropZones } =
        useChatControls();
    const { clearChatContext } = useChats();
    const { addFile, touchFile } = useUploadedFiles();

    const { chats } = useChats();
    const { isLoggedOutChat, showSignupGate, chat: loggedOutChat } = useLoggedOutChat();
    let chat = id !== null ? getChatHistory(chats, id) : null;
    if (!chat && isLoggedOutChat) {
        chat = loggedOutChat;
    }

    const loading = !!chat?.loading;

    const { isReady } = useUserAuth();

    const uploadDisabled = uploadControls.isLoading;
    const notReadyToSend = isLoggedOutChat ? loading : loading || !isReady || (!!id && !chat);

    useDocumentPaste((event) => {
        if ((event.target as HTMLElement)?.id === 'custom_url_input') return;
        if (uploadDisabled) return;
        if (event.clipboardData?.files && event.clipboardData?.files.length > 0) {
            const file = event.clipboardData.files[0];
            if (isLoggedOutChat && getFileType(file) !== 'image') return showSignupGate('feature');
            if (isLoggedOutChat) return uploadControls.setMediaBase64(file);
            const mimeType = mime.lookup(file.name);
            if (mimeType && mediaMimeTypes.includes(mimeType)) {
                uploadControls.uploadMedia(file);
            }
        } else {
            const data = event.clipboardData?.getData('text');
            const urlMatch = data?.match(urlRegex)?.[0];
            const isValid = urlMatch && uploadControls.isValidMediaUrl(urlMatch);
            if (isValid) {
                if (urlMatch === data) event.preventDefault();
                uploadControls.uploadMediaFromURl(urlMatch);
            }
        }
    });

    const handleMediaDrop = (e: React.DragEvent) => {
        e.preventDefault();
        if (uploadDisabled) return;
        const item = e.dataTransfer.items[0];
        const file = item.getAsFile();
        if (file) {
            if (isLoggedOutChat && getFileType(file) !== 'image') return showSignupGate('feature');
            if (isLoggedOutChat) return uploadControls.setMediaBase64(file);
            uploadControls.uploadMedia(file);
            setOption({ retrievalDataset: undefined });
        }
    };

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        handleSubmit();
    };

    const handleSubmit = () => {
        let autoClearContext = false;
        if (lastContextLength(chat?.history) >= configValue.conversationLengthLimit) {
            autoClearContext = true;
        }
        if (chat?.history?.at(-1)?.finish_reason === 'max-tokens-reached') {
            autoClearContext = true;
        }
        if (notReadyToSend) return;
        if (uploadControls.error || uploadControls.isLoading || !input.trim()) return;
        handleMessageSend(
            input,
            id,
            {
                mediaUrl: uploadControls.mediaUrl ?? undefined,
                mediaType: uploadControls.mediaType ?? undefined,
                fileUrl: uploadControls.fileUrl ?? undefined,
                ...requestOption,
            },
            autoClearContext,
        );
        if (uploadControls.file) {
            addFile({
                filename: uploadControls.file.name,
                url: uploadControls.mediaUrl || uploadControls.fileUrl!,
                type: uploadControls.mediaType || 'code_file',
            });
        } else if (uploadControls.existingFile) {
            touchFile(uploadControls.mediaUrl || uploadControls.fileUrl!);
        }
        uploadControls.clear();
        setInput('');
    };

    const handleClearContext = (id: string) => {
        const el = document.getElementById('message-list');
        if (el) {
            el.scrollTo(0, el.scrollHeight);
        }
        clearChatContext(id);
    };

    const removeDocument = () => {
        if (requestOption.retrievalDataset) {
            setOption({ retrievalDataset: undefined });
        } else {
            uploadControls.clear();
        }
    };
    const { drawer, controls, overflow } = useMemo(
        () =>
            calculateVisibility(
                enabledFeatures,
                requestOption,
                uploadControls.fileUrl && uploadControls.filename,
                breakpoint,
                isLoggedOutChat,
            ),
        [enabledFeatures, requestOption, uploadControls.fileUrl, uploadControls.filename, breakpoint, isLoggedOutChat],
    );

    return (
        <Box
            borderRadius={'8px'}
            border={showDropZones && !uploadDisabled ? '2px dashed' : ''}
            borderColor={'whiteAlpha.700'}
            position={'relative'}
            onDrop={handleMediaDrop}
        >
            {showDropZones && !uploadDisabled && (
                <Box
                    position={'absolute'}
                    top={0}
                    left={0}
                    width={'100%'}
                    h={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Text fontSize={'lg'}>Drop file here to upload</Text>
                </Box>
            )}
            <Box
                opacity={showDropZones && !uploadDisabled ? 0.3 : 1}
                border={'1px solid'}
                background={'background-main'}
                borderColor={'border-main'}
                borderRadius={'8px'}
                _focusWithin={{ outline: '2px solid', outlineColor: 'outline-alt' }}
            >
                <MediaRow />
                <Flex
                    alignItems={'center'}
                    position={'relative'}
                    padding={['8px 10px', '12px 20px']}
                    as={'form'}
                    flexWrap={'wrap'}
                    onSubmit={handleFormSubmit}
                >
                    {isListening && (
                        <Box
                            className={cl.blink}
                            marginRight={'15px'}
                            background={'red100'}
                            width={'12px'}
                            height={'12px'}
                            borderRadius={'50%'}
                        />
                    )}
                    <AutoResizableTextarea
                        listening={isListening}
                        value={input}
                        onChange={setInput}
                        onSubmit={handleSubmit}
                    />
                </Flex>
                <Flex
                    background={'background-secondary'}
                    borderRadius={'0 0 8px 8px'}
                    padding={['6px', '12px']}
                    gap={'12px'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                >
                    <FileUploadButton isLoading={uploadControls.isLoading} isDisabled={uploadDisabled} />
                    {id !== null && !isLoggedOutChat && (
                        <>
                            <Divider height={'28px'} orientation="vertical" borderColor={'border-alt'} />
                            <Tooltip label={'Clear chat context'}>
                                <IconButton
                                    isRound
                                    size={'sm'}
                                    fontSize={'20px'}
                                    variant={'ghost'}
                                    icon={<AiOutlineClear />}
                                    aria-label={'Clear context'}
                                    onClick={() => id && handleClearContext(id)}
                                />
                            </Tooltip>
                        </>
                    )}
                    {controls.document && (
                        <DocumentTag disabled={id !== null} onRemove={removeDocument}>
                            {requestOption.retrievalDataset || uploadControls.filename}
                        </DocumentTag>
                    )}
                    {uploadControls.errorMsg && <Text color={'red.400'}>{uploadControls.errorMsg}</Text>}
                    <Flex flex={1} alignItems={'center'} justifyContent={'flex-end'}>
                        <Flex marginRight={'8px'} gap={'24px'}>
                            {controls.codeInterpreter && (
                                <ChatToggle
                                    isChecked={requestOption.useCodeInterpreter}
                                    onChange={() =>
                                        setOption({ useCodeInterpreter: !requestOption.useCodeInterpreter })
                                    }
                                >
                                    code interpreter
                                </ChatToggle>
                            )}
                            {controls.searchEngine && (
                                <ChatToggle
                                    isChecked={requestOption.useSearchEngine}
                                    onChange={() => setOption({ useSearchEngine: !requestOption.useSearchEngine })}
                                >
                                    search engine
                                </ChatToggle>
                            )}
                            {controls.divider && (
                                <Divider
                                    marginLeft={'-8px'}
                                    height={'28px'}
                                    orientation="vertical"
                                    borderColor={'border-alt'}
                                />
                            )}
                        </Flex>
                        {controls.options && (
                            <IconButton
                                aria-label={'more options'}
                                isActive={!!controls.optionsActivated}
                                onClick={optionsModal.onOpen}
                                size="sm"
                                fontSize={'18px'}
                                variant={'ghost'}
                                icon={<BsSliders2 />}
                            />
                        )}
                        <SendButton
                            loading={notReadyToSend}
                            setListening={setListening}
                            setInput={setInput}
                            input={input}
                            onClick={handleSubmit}
                        />
                    </Flex>
                    {overflow.document && (
                        <Flex width={'100%'} marginTop={'4px'}>
                            <DocumentTag disabled={id !== null} onRemove={removeDocument}>
                                {requestOption.retrievalDataset || uploadControls.filename}
                            </DocumentTag>
                        </Flex>
                    )}
                </Flex>
            </Box>
            <RecentUploadsModal />
            <DatasetsDrawer />
            {controls.options && <OptionsDrawerComponent drawer={drawer} />}
        </Box>
    );
}

function calculateVisibility(
    enabledFeatures: IAppConfigState['enabledFeatures'],
    requestOption: IRequestOptionState,
    filename: string | null,
    breakpoint: string,
    isLoggedoutChat: boolean,
) {
    const drawer = {
        modelSelector: enabledFeatures.modelSelector,
        personaSelector: enabledFeatures.personaSelector,
        codeInterpreter: false,
    };
    const controls = {
        searchEngine: !isLoggedoutChat && enabledFeatures.searchEngine && !requestOption.retrievalDataset,
        codeInterpreter: !isLoggedoutChat && !!enabledFeatures.codeInterpreter,
        options: Object.values(drawer).filter((n) => n).length > 0,
        optionsActivated:
            !isLoggedoutChat &&
            ((drawer.modelSelector && requestOption.modelName) || (drawer.personaSelector && !!requestOption.persona)),
        document: !!(requestOption.retrievalDataset || filename),
        divider: false,
    };
    const overflow = {
        document: false,
    };
    if (breakpoint === 'base' || breakpoint === 'sm') {
        drawer.codeInterpreter = controls.codeInterpreter;

        controls.codeInterpreter = false;
        controls.options = Object.values(drawer).filter((n) => n).length > 0;

        if (drawer.codeInterpreter) {
            controls.optionsActivated ||= requestOption.useCodeInterpreter;
        }
    }

    if (breakpoint === 'base') {
        overflow.document = controls.document;
        controls.document = false;
    }
    controls.divider = controls.options && (controls.searchEngine || controls.codeInterpreter);
    return { drawer, controls, overflow };
}

type SendButtonProps = {
    loading: boolean;
    input: string;
    setListening: (listening: boolean) => void;
    onClick: () => void;
    setInput: (val: string) => void;
};

function SendButton({ loading, input, setListening, onClick, setInput }: SendButtonProps) {
    const { transcript, listening, resetTranscript, isMicrophoneAvailable, browserSupportsSpeechRecognition } =
        useSpeechRecognition({ clearTranscriptOnListen: true });
    useEffect(() => {
        setListening(listening);
        if (!listening) {
            return;
        }
        setInput(transcript);
    }, [listening, transcript]);
    if (!browserSupportsSpeechRecognition || !isMicrophoneAvailable || (!listening && input.length > 0)) {
        return (
            <Tooltip label={'Send input'}>
                <IconButton
                    isLoading={loading}
                    marginLeft={'8px'}
                    isDisabled={!input}
                    aria-label={'send input'}
                    onClick={onClick}
                    size={'sm'}
                    isRound
                    fontSize={'20px'}
                    variant={'ghost'}
                    icon={<MdSend />}
                />
            </Tooltip>
        );
    }

    return (
        <>
            {listening && (
                <Tooltip label="Cancel">
                    <IconButton
                        aria-label="Cancel"
                        icon={<BsTrashFill />}
                        onClick={() => {
                            SpeechRecognition.stopListening();
                            setInput('');
                        }}
                        size="sm"
                        fontSize={'20px'}
                        isRound
                        variant={'ghost'}
                        color={'red100'}
                    />
                </Tooltip>
            )}
            <Tooltip label={listening ? 'Send input' : 'Speech to input'}>
                <IconButton
                    isLoading={loading}
                    marginLeft={'8px'}
                    className={listening ? cl.pulse : ''}
                    bg={listening ? 'white15' : ''}
                    icon={listening ? <MdSend /> : <BsFillMicFill />}
                    aria-label={listening ? 'Send input' : 'Speech to input'}
                    onClick={() => {
                        if (listening) {
                            SpeechRecognition.stopListening();
                            onClick();
                        } else {
                            resetTranscript();
                            SpeechRecognition.startListening({ continuous: true });
                        }
                    }}
                    size="sm"
                    fontSize={'20px'}
                    isRound
                    variant={'ghost'}
                />
            </Tooltip>
        </>
    );
}

const MODES = {
    MEDIA: 'media',
    RETRIEVAL: 'retrieval',
    CODE_INTERPRETER: 'code_interpreter',
    SELECT_MODE: 'select_mode',
} as const;

function FileUploadButton({ isDisabled, isLoading }: { isDisabled: boolean; isLoading: boolean }) {
    const [mode, setMode] = useState<'media' | 'retrieval' | 'code_interpreter' | null>(null);
    const formRef = useRef<HTMLInputElement>(null);
    const { enabledFeatures } = useAppConfig();
    const { uploadControls, recentUploadsModal, setOption, datasetsModal, datasetControls } = useChatControls();
    const { files } = useUploadedFiles();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isLoggedOutChat, showSignupGate } = useLoggedOutChat();
    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        if (mode === 'media') {
            if (isLoggedOutChat) {
                uploadControls.setMediaBase64(file);
                return;
            }
            uploadControls.uploadMedia(file);
            setOption({ retrievalDataset: undefined });
        } else if (mode === 'code_interpreter') {
            uploadControls.uploadFile(file).then(() => {
                setOption({ useCodeInterpreter: true });
            });
            setOption({ retrievalDataset: undefined });
        } else {
            datasetsModal.onOpen();
            datasetControls.fileSelected(file);
        }
    };
    const handleClick =
        (
            mode: 'media' | 'retrieval' | 'code_interpreter',
            mimetype: 'audio' | 'image' | 'video' | 'photovideo' | 'code' | 'dataset' | 'context',
        ) =>
        () => {
            if (isLoggedOutChat && mimetype !== 'image') {
                return showSignupGate('feature');
            }
            const accept = {
                audio: 'audio/wav,audio/wave,audio/x-wav,audio/flac,audio/x-flac,audio/ogg,audio/mp3,audio/mpeg',
                photovideo:
                    'image/jpeg,image/png,image/gif,image/tiff,image/x-icon,image/bmp,image/webp,video/mp4,video/avi,video/x-msvideo,video/mpeg,video/webm,video/quicktime',
                video: 'video/mp4,video/avi,video/x-msvideo,video/mpeg,video/webm,video/quicktime',
                context: 'application/pdf',
                image: 'image/jpeg,image/png,image/gif,image/tiff,image/x-icon,image/bmp,image/webp',
                code: 'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf',
                dataset: 'multipart/*,message/*,model/*,application/*,text/*,.md',
            }[mimetype];
            formRef.current!.accept = accept!;
            formRef.current!.click();
            setMode(mode);
        };
    const handleOpen = () => {
        onOpen();
    };
    return (
        <>
            <Menu isOpen={isOpen} onOpen={handleOpen} onClose={onClose} strategy="fixed">
                <Tooltip label={'Attach a file'}>
                    <MenuButton
                        as={IconButton}
                        icon={<BiImageAdd />}
                        aria-label={'upload file'}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isRound={true}
                        variant="solid"
                        fontSize="20px"
                        size={'sm'}
                    />
                </Tooltip>

                <MenuList>
                    <MenuItem onClick={handleClick(MODES.MEDIA, 'image')} icon={<BsImage />}>
                        Photos{' '}
                        <Text fontSize={'14px'} as={'span'} color={'text-subtle'}>
                            | 12MB
                        </Text>
                    </MenuItem>
                    {enabledFeatures.videoUpload && (
                        <MenuItem onClick={handleClick(MODES.MEDIA, 'video')} icon={<BsCameraVideo />}>
                            Videos{' '}
                            <Text fontSize={'14px'} as={'span'} color={'text-subtle'}>
                                | 30MB & 1 min
                            </Text>
                        </MenuItem>
                    )}
                    {/*<MenuItem onClick={handleClick(MODES.MEDIA, 'photovideo')} icon={<BsImages />}>*/}
                    {/*    Photos & Videos*/}
                    {/*</MenuItem>*/}
                    {enabledFeatures.audioUpload && (
                        <MenuItem onClick={handleClick(MODES.MEDIA, 'audio')} icon={<BsSoundwave />}>
                            Audio
                        </MenuItem>
                    )}
                    {enabledFeatures.longContext && (
                        <MenuItem onClick={handleClick(MODES.MEDIA, 'context')} icon={<BsFiletypeDoc />}>
                            PDF
                        </MenuItem>
                    )}
                    {enabledFeatures.retrieval && (
                        <MenuItem onClick={handleClick(MODES.RETRIEVAL, 'dataset')} icon={<BsDatabaseAdd />}>
                            New Dataset
                        </MenuItem>
                    )}
                    {enabledFeatures.codeInterpreter && (
                        <MenuItem
                            onClick={handleClick(MODES.CODE_INTERPRETER, 'code')}
                            icon={<BsFillFileEarmarkRuledFill />}
                        >
                            Code Input
                        </MenuItem>
                    )}
                    {enabledFeatures.customUrl && (
                        <MenuItem onClick={() => uploadControls.useCustomUrl('')} icon={<BsLink45Deg />}>
                            Custom URL
                        </MenuItem>
                    )}
                    <MenuDivider />
                    <MenuItem
                        isDisabled={files.length === 0}
                        onClick={recentUploadsModal.onOpen}
                        icon={<BsClockHistory />}
                    >
                        Recent Files
                    </MenuItem>
                    {enabledFeatures.retrieval && (
                        <MenuItem
                            isDisabled={datasetControls.datasets.length === 0}
                            onClick={datasetsModal.onOpen}
                            icon={<BsDatabase />}
                        >
                            Available Datasets
                        </MenuItem>
                    )}
                </MenuList>
            </Menu>
            <Input onChange={handleUpload} ref={formRef} type={'file'} value="" display={'none'} />
        </>
    );
}

function MediaRow() {
    const {
        uploadControls: {
            filename,
            error,
            setErrorMsg,
            setError,
            objectUrl,
            mediaUrl,
            isLoading,
            mediaType,
            clear,
            reUploadMedia,
        },
    } = useChatControls();
    if ((!objectUrl && !mediaUrl && mediaType !== 'custom') || !mediaType) return null;
    return (
        <Flex gap={'16px'} padding={['6px 8px 0 8px', '12px 16px 0 16px']}>
            <MediaPreview
                src={objectUrl || mediaUrl!}
                filename={filename ?? undefined}
                isLoading={isLoading}
                onRetry={reUploadMedia}
                onRemove={clear}
                onError={setError}
                setErrorMsg={setErrorMsg}
                error={error}
                mediaType={mediaType}
            />
        </Flex>
    );
}

function useDocumentPaste(cb = (event: ClipboardEvent) => {}) {
    const ref = useRef(cb);
    ref.current = cb;
    useEffect(() => {
        function eventHandler(event: ClipboardEvent) {
            ref.current(event);
        }

        document.addEventListener('paste', eventHandler);
        return () => document.removeEventListener('paste', eventHandler);
    }, []);
}

const urlRegex = /(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+/;
