import React, { useState } from 'react';
import { BiText } from 'react-icons/bi';
import {
    BsArrowRight,
    BsFillFileEarmarkTextFill,
    BsFilm,
    BsGlobe2,
    BsImage,
    BsLock,
    BsSoundwave,
} from 'react-icons/bs';
import { MdLock } from 'react-icons/md';

import { useChatControls } from '@/contexts/chat-controls';
import { useGetMultiModalQuestions } from '@/hooks/use-get-random-questions';
import { useSendMessage } from '@/hooks/use-send-message';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';
import { Box, Button, ButtonGroup, Flex, Heading, List, ListItem, Text, Tooltip } from '@chakra-ui/react';

import { isProd } from '../../environments';

const typeIcon = {
    text: (
        <Box as={'span'} color={'text-subtle'}>
            <BiText />
        </Box>
    ),
    image: (
        <Box as={'span'} color={'text-subtle'}>
            <BsImage />
        </Box>
    ),
    video: (
        <Box as={'span'} color={'text-subtle'}>
            <BsFilm />
        </Box>
    ),
    audio: (
        <Box as={'span'} color={'text-subtle'}>
            <BsSoundwave />
        </Box>
    ),
    search: (
        <Box as={'span'} color={'text-subtle'}>
            <BsGlobe2 />
        </Box>
    ),
    retrieval: (
        <Box as={'span'} color={'text-subtle'}>
            <BsFillFileEarmarkTextFill />
        </Box>
    ),
};

export function MultiModalEmptyChat() {
    const examples = useGetMultiModalQuestions();
    const { handleMessageSend } = useSendMessage();
    const { requestOption } = useChatControls();
    const { isLoggedOutChat, showSignupGate } = useLoggedOutChat();
    return (
        <Flex alignItems={'center'} justifyContent={'center'} minHeight={'100%'}>
            <Box>
                {!isLoggedOutChat && <ModelSelector />}
                {isLoggedOutChat && (
                    <Box w={'320px'}>
                        <Flex gap={'12px'}>
                            <Heading mb={3} as={'h1'}>
                                Reka Flash
                            </Heading>
                            <Button onClick={() => showSignupGate('models')} size="sm" variant={'link'}>
                                more models
                            </Button>
                        </Flex>
                        <Text mb={8}>
                            Our turbo-class highly capable model optimized for common everyday questions.
                        </Text>
                    </Box>
                )}
                <List>
                    {examples.map((example, index) => (
                        <ListItem key={index} mb={'12px'}>
                            <Button
                                onClick={() =>
                                    handleMessageSend(example.prompt, null, {
                                        ...requestOption,
                                        ...example.params,
                                    })
                                }
                                variant={'link'}
                                leftIcon={typeIcon[example.type]}
                                rightIcon={<BsArrowRight />}
                            >
                                {example.label}
                            </Button>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Flex>
    );
}

function ModelSelector() {
    const { setOption, requestOption } = useChatControls();
    const { enabledFeatures } = useAppConfig();
    const modelName = requestOption.modelName;
    const isFlash =
        !modelName || modelName.includes('flash') || (!enabledFeatures.rekaCore && modelName.includes('default'));
    const isCore = modelName.includes('core') || (enabledFeatures.rekaCore && modelName.includes('default'));
    const isEdge = modelName.includes('edge');

    return (
        <Box maxW={'100%'} width={'300px'} margin={'auto'}>
            <ButtonGroup width={'100%'} mb={'12px'} size="lg" isAttached variant="purpleOutline">
                {!enabledFeatures.rekaCore && (
                    <Tooltip label="Coming soon, contact us for early access">
                        <Button leftIcon={<MdLock />} isDisabled={!enabledFeatures.rekaCore}>
                            Core
                        </Button>
                    </Tooltip>
                )}
                {enabledFeatures.rekaCore && (
                    <Button
                        onClick={() => {
                            setOption({ modelName: 'reka-core' });
                        }}
                        isActive={isCore}
                    >
                        Core
                    </Button>
                )}
                <Button
                    onClick={() => {
                        setOption({ modelName: 'reka-flash' });
                    }}
                    isActive={isFlash}
                >
                    Flash
                </Button>
                <Button
                    onClick={() => {
                        setOption({ modelName: 'reka-edge' });
                    }}
                    isActive={isEdge}
                >
                    Edge
                </Button>
            </ButtonGroup>
            <Box height={'70px'}>
                {isCore && <Text fontSize={'12px'}>Our largest and most capable model able to handle any task.</Text>}
                {isFlash && (
                    <Text fontSize={'12px'}>
                        Our turbo-class highly capable model optimized for common everyday questions.
                    </Text>
                )}
                {isEdge && (
                    <Text fontSize={'12px'}>
                        Our smallest model, ideal for cases where speed is paramount. Also suitable for local or
                        on-device usages.
                    </Text>
                )}
            </Box>
        </Box>
    );
}
