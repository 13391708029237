import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    BsCheckCircle,
    BsFileEarmarkText,
    BsInfoCircle,
    BsPencil,
    BsTrashFill,
    BsXCircleFill,
    BsXLg,
} from 'react-icons/bs';
import * as yup from 'yup';

import { useChatControls } from '@/contexts/chat-controls';
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    Icon,
    IconButton,
    Input,
    List,
    ListItem,
    Spinner,
    Text,
    Textarea,
    Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

export function DatasetsDrawer() {
    const inputRef = useRef<HTMLInputElement>(null);
    const { datasetsModal, datasetControls, setOption, uploadControls } = useChatControls();
    const [showDelete, setShowDelete] = useState(false);
    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            datasetControls.fileSelected(file);
        }
    };
    const handleClick = () => {
        inputRef.current!.click();
    };
    return (
        <Drawer onClose={datasetsModal.onClose} isOpen={datasetsModal.isOpen} size={'xs'}>
            <DrawerOverlay />
            <DrawerContent background={'modal-background'}>
                <DrawerCloseButton />
                <DrawerHeader as={'h1'} fontSize={'24px'}>
                    Available Datasets
                </DrawerHeader>
                <DrawerBody>
                    {datasetControls.status !== 'initial' && (
                        <>
                            {datasetControls.status === 'preupload' ? <DatasetForm /> : <CurrentDataset />}
                            <Divider mb={'12px'} />
                        </>
                    )}
                    <Flex alignItems={'center'} gap={'12px'} mb={'12px'} justifyContent={'space-between'}>
                        {datasetControls.status === 'initial' ? (
                            <Button onClick={handleClick} flex={1}>
                                Create a new dataset
                            </Button>
                        ) : (
                            <Heading as={'h3'} size={'sm'} flex={1}>
                                Past datasets
                            </Heading>
                        )}
                        <IconButton
                            onClick={() => setShowDelete((v) => !v)}
                            isRound
                            aria-label={'delete'}
                            icon={showDelete ? <BsXLg /> : <BsPencil />}
                            variant={'ghost'}
                        />
                    </Flex>
                    <Input
                        onChange={handleUpload}
                        ref={inputRef}
                        accept="multipart/*,message/*,model/*,application/*,text/*,.md"
                        type={'file'}
                        value=""
                        display={'none'}
                    />
                    <List>
                        {datasetControls.datasets.map((name) => (
                            <ListItem marginBottom={'12px'} key={name}>
                                <Dataset name={name} showDelete={showDelete} />
                            </ListItem>
                        ))}
                    </List>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}

function Dataset({ name, showDelete }: { name: string; showDelete: boolean }) {
    const { datasetControls, uploadControls, datasetsModal, setOption } = useChatControls();
    const [loading, setLoading] = useState(false);
    const [checking, setChecking] = useState(false);
    const [requiresPrep, setRequiresPrep] = useState<boolean | null>(
        name in datasetControls.preparedMap ? !datasetControls.preparedMap[name] : null,
    );
    const handleDelete = async () => {
        setLoading(true);
        await datasetControls.deleteDataset(name);
        setLoading(false);
    };
    const handleStart = async (name: string) => {
        if (loading || checking) return;

        if (requiresPrep) {
            datasetControls.clear();
            setChecking(true);
            const ok = await datasetControls.prepareDataset(name);
            setChecking(false);
            setRequiresPrep(!ok);
            return;
        }
        if (requiresPrep === null) {
            setChecking(true);
            const ready = await datasetControls.checkStatus(name);
            setChecking(false);
            if (!ready) {
                setRequiresPrep(true);
                return;
            }
        }
        datasetControls.clear();
        uploadControls.clear();
        datasetsModal.onClose();
        setOption({ retrievalDataset: name });
    };
    return (
        <ButtonGroup width={'100%'} isAttached variant="solid">
            <Button
                leftIcon={
                    checking ? (
                        <Spinner />
                    ) : requiresPrep ? (
                        <Tooltip label={'Dataset requires processing'}>
                            <Icon color={'yellow.100'} fontSize={'16px'} viewBox={'0 0 16 16'} marginRight={'8px'}>
                                <BsInfoCircle />
                            </Icon>
                        </Tooltip>
                    ) : undefined
                }
                disabled={loading}
                width={'100%'}
                display={'flex'}
                onClick={() => handleStart(name)}
            >
                <Text textAlign={'left'} width={'100%'} overflow={'hidden'} textOverflow={'ellipsis'}>
                    {name}
                </Text>
            </Button>
            {showDelete && (
                <IconButton onClick={handleDelete} aria-label={'delete'} isLoading={loading} icon={<BsTrashFill />} />
            )}
        </ButtonGroup>
    );
}

const datasetResolver = yupResolver(
    yup.object().shape({
        name: yup.string().required().max(64),
        description: yup.string().max(128),
    }),
);

function DatasetForm() {
    const { datasetControls } = useChatControls();
    const { datasets, file, createDataset } = datasetControls;
    const { handleSubmit, register, setError, formState } = useForm({
        resolver: datasetResolver,
        criteriaMode: 'all',
        mode: 'onSubmit',
        values: {
            name: datasetControls.generateFilename(file!),
            description: '',
        },
    });

    const formSubmit = async (data: any) => {
        if (datasets.includes(data.name)) {
            setError('name', { message: 'Dataset name already in use' });
            return;
        }
        try {
            await createDataset({ ...data, file });
        } catch (error) {
            setError('root', { message: 'Unable to create dataset' });
        }
    };
    return (
        <Grid
            templateAreas={`
        "preview details"
        "form form"
        `}
            rowGap={'18px'}
            columnGap={'18px'}
            templateColumns={'auto 1fr'}
        >
            <GridItem
                gridArea={'preview'}
                border={'1px solid'}
                borderColor={'border-alt'}
                borderRadius={'6px'}
                width={'50px'}
                height={'50px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Icon viewBox={'0 0 20 20'} fontSize={'20px'}>
                    <BsFileEarmarkText />
                </Icon>
            </GridItem>
            <GridItem minWidth={0} gridArea={'details'}>
                <Text textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'}>
                    {file?.name}
                </Text>
                <Text>{Math.round((file?.size || 1) / 1000) || 1} kb</Text>
            </GridItem>
            <GridItem gridArea={'form'}>
                <form onSubmit={handleSubmit(formSubmit)}>
                    <FormControl
                        mb={'18px'}
                        isInvalid={
                            !!((formState.isSubmitted || formState.touchedFields['name']) && formState.errors['name'])
                        }
                    >
                        <FormLabel>Dataset Name</FormLabel>
                        <Input {...register('name')} type={'text'} />
                        <FormErrorMessage>{formState.errors['name']?.message as string}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                        mb={'18px'}
                        isInvalid={
                            !!(
                                (formState.isSubmitted || formState.touchedFields['description']) &&
                                formState.errors['description']
                            )
                        }
                    >
                        <FormLabel>Description</FormLabel>
                        <Textarea {...register('description')} />
                        <FormErrorMessage>{formState.errors['description']?.message as string}</FormErrorMessage>
                    </FormControl>
                    <Flex mb={'24px'} gap={'12px'} justifyContent={'space-between'}>
                        <Button type="submit" variant={'solid'}>
                            Create dataset
                        </Button>
                        <Button onClick={datasetControls.clear} variant={'ghost'}>
                            cancel
                        </Button>
                    </Flex>
                </form>
            </GridItem>
        </Grid>
    );
}

function CurrentDataset() {
    const { datasetControls, setOption, datasetsModal, uploadControls } = useChatControls();
    const router = useRouter();
    const handleStart = () => {
        router.push('/chat');
        datasetControls.clear();
        uploadControls.clear();
        setOption({ retrievalDataset: datasetControls.datasetName ?? undefined });
        datasetsModal.onClose();
    };
    return (
        <Box border={'1px solid'} borderColor={'border-alt'} borderRadius={'6px'} padding={'12px 16px'}>
            <Flex gap={'16px'} fontSize={'md'} marginBottom={'12px'}>
                {datasetControls.isLoading && <Spinner />}
                {!datasetControls.isLoading && (
                    <>
                        {datasetControls.status === 'completed' && (
                            <Icon color={'green'} viewBox={'0 0 24 24'} fontSize={'24px'}>
                                <BsCheckCircle />
                            </Icon>
                        )}
                        {datasetControls.status === 'error' && (
                            <Icon color={'red.600'} viewBox={'0 0 24 24'} fontSize={'24px'}>
                                <BsXCircleFill />
                            </Icon>
                        )}
                    </>
                )}

                <Text>{datasetControls.datasetName}</Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignItems={'center'} color={'text-subtle'} fontSize={'sm'}>
                {datasetControls.status !== 'error' && (
                    <Button
                        isDisabled={datasetControls.status !== 'completed'}
                        onClick={handleStart}
                        size={'sm'}
                        variant={'solid'}
                    >
                        {datasetControls.status === 'preupload' && <Text>Uploading</Text>}
                        {datasetControls.status === 'preparing' && <Text>Preparing</Text>}
                        {datasetControls.status === 'completed' && <Text>Ready to use</Text>}
                    </Button>
                )}
                {datasetControls.status === 'error' && (
                    <Button onClick={datasetControls.clear} size={'sm'} variant={'solid'}>
                        Reset
                    </Button>
                )}

                <Button onClick={datasetControls.clear} size="sm" variant={'ghost'}>
                    cancel
                </Button>
            </Flex>
        </Box>
    );
}
