import { useMemo } from 'react';

import { useAppConfig } from '@/store/hooks/use-app-config';

//Previous questions
// const questionsArray = [
//     'How expensive is it to buy a condo in Tokyo?',
//     'What is the capital of Canada?',
//     'How old are the Egyptian pyramids?',
//     'What is your favorite hobby?',
//     'How old are you?',
//     'Who trained you?',
//     'What are some healthy recipes using salmon?',
//     'Write a poem about science.',
//     'Write python code to parse a csv file.',
//     "Explain Pythagoras' theorem to me.",
//     'Write a 3 day itinerary for a trip to Singapore.',
//     'What are some Latin words commonly used in English?',
//     'How do I sort a list in Javascript?',
// ];

type multiModalQuestion = textQuestion | mediaQuestion | retrievalQuestion | searchQuestion;

type textQuestion = {
    label: string;
    type: 'text';
    prompt: string;
    params?: {};
};

type mediaQuestion = {
    label: string;
    type: 'image' | 'audio' | 'video';
    prompt: string;
    params: {
        mediaUrl: string;
        mediaType: 'image' | 'audio' | 'video';
    };
};

type retrievalQuestion = {
    label: string;
    type: 'retrieval';
    prompt: string;
    params: {
        retrievalDataset: string;
    };
};

type searchQuestion = {
    label: string;
    type: 'search';
    prompt: string;
    params: {
        useSearchEngine: true;
    };
};

const multiModalExamples: multiModalQuestion[] = [
    {
        label: 'Explain a complex concept',
        type: 'text',
        prompt: 'What is purchasing power parity, and why is it essential to consider when calculating GDP per capita?',
    },
    {
        label: 'Generate travel ideas',
        type: 'text',
        prompt: 'Write a 3 day itinerary for a trip to Singapore.',
    },
    {
        label: 'Generate cooking ideas',
        type: 'text',
        prompt: 'What are some healthy recipes using salmon?',
    },
    {
        label: 'Solve a programming task',
        type: 'text',
        prompt: 'Write python code to parse a csv file.',
    },
    {
        label: 'Help writing an email',
        type: 'text',
        prompt: 'Help me write an email asking for a refund for a faulty product.',
    },
    {
        label: 'Translate text in another language',
        type: 'text',
        prompt: 'What does this mean in English? 恭喜发财',
    },
    {
        label: 'Write a story',
        type: 'text',
        prompt: 'Write a bedtime story for my 1.5 year old, Robin. Make him the star of the story and include his blue bunny toy too.',
    },
    {
        label: 'Learn a new language',
        type: 'text',
        prompt: 'I want to learn Bahasa Indonesia - what are five simple phrases to get started?',
    },
    {
        label: 'Debug your code',
        type: 'text',
        prompt: 'Why is this python code failing with NameError? my_variable = true',
    },
    {
        label: 'Test your knowledge with a custom quiz',
        type: 'text',
        prompt: 'I have been studying the Ancient Greeks in high school. Ask me a multiple choice question to test my knowledge.',
    },
    {
        label: 'Fix your grammar and spelling.',
        type: 'text',
        prompt: 'Fix the grammar and spelling in the following sentence: Its in there best interests.',
    },
    {
        label: 'Write captions for an image',
        type: 'image',
        prompt: 'Write a caption for this image',
        params: {
            mediaUrl: 'https://reka-prod-user-images.s3.eu-west-2.amazonaws.com/examples/Schneeberg_from_Sopron.jpg',
            mediaType: 'image',
        },
    },
    {
        label: 'Explain this geometry diagram',
        type: 'image',
        prompt: 'Explain the math here',
        params: {
            mediaUrl: 'https://reka-prod-user-images.s3.eu-west-2.amazonaws.com/examples/geometry_diagram.jpeg',
            mediaType: 'image',
        },
    },
    {
        label: 'Play a game of Geoguessr',
        type: 'image',
        prompt: 'I am playing Geoguessr. Help me guess where in the world this is.',
        params: {
            mediaUrl: 'https://reka-prod-user-images.s3.eu-west-2.amazonaws.com/examples/geoguessr.jpg',
            mediaType: 'image',
        },
    },
    // {
    //     label: 'Identify the dish in the photo',
    //     type: 'image',
    //     prompt: 'What polish dish is this?',
    //     params: {
    //         mediaUrl: 'https://reka-prod-user-images.s3.eu-west-2.amazonaws.com/examples/polish-dish.jpg',
    //         mediaType: 'image',
    //     },
    // },
    {
        label: 'Locate the origins of a dish',
        type: 'image',
        prompt: 'In what country can I find something like this?',
        params: {
            mediaUrl: 'https://reka-prod-user-images.s3.eu-west-2.amazonaws.com/examples/where-to-find.png',
            mediaType: 'image',
        },
    },
    // {
    //     label: 'Identify a plant',
    //     type: 'image',
    //     prompt: 'What am I growing here?',
    //     params: {
    //         mediaUrl: 'https://reka-prod-user-images.s3.eu-west-2.amazonaws.com/examples/what-is-this-plant.png',
    //         mediaType: 'image',
    //     },
    // },
    {
        label: 'Identify audio in recording',
        type: 'audio',
        prompt: 'Could you identify the various elements present in this recording?',
        params: {
            mediaUrl: 'https://reka-prod-user-images.s3.eu-west-2.amazonaws.com/examples/streamandbirds.wav',
            mediaType: 'audio',
        },
    },
    {
        label: 'Discuss audio recording',
        type: 'audio',
        prompt: 'What is the weather like outside if I hear a sound like this in my room?',
        params: {
            mediaUrl: 'https://reka-prod-user-images.s3.eu-west-2.amazonaws.com/examples/lightning.wav',
            mediaType: 'audio',
        },
    },
    // {
    //     label: 'Discuss video content',
    //     type: 'video',
    //     prompt: 'What are the main elements in this video?',
    //     params: {
    //         mediaUrl: 'https://reka-prod-user-images.s3.eu-west-2.amazonaws.com/examples/cow_on_grass.mp4',
    //         mediaType: 'video',
    //     },
    // },
    // { // this isnt giving good enough responses
    //     label: 'Discuss stock performance',
    //     type: 'search',
    //     prompt: "Which company's stock has the largest 52-week gain and what are three reasons they are performing this well",
    //     params: {
    //         useSearchEngine: true,
    //     },
    // },
    {
        label: 'Weekend ideas based on the weather',
        type: 'search',
        prompt: 'How is the weather forecasted to be in London this weekend, and could you suggest three activities suitable for such conditions?',
        params: {
            useSearchEngine: true,
        },
    },
];

export const useGetMultiModalQuestions = (len = 3, repeat = 2): multiModalQuestion[] => {
    const { enabledFeatures } = useAppConfig();
    return useMemo(() => {
        let examples = [...multiModalExamples];
        if (!enabledFeatures.audioUpload) {
            examples = examples.filter((e) => e.type !== 'audio');
        }
        if (!enabledFeatures.videoUpload) {
            examples = examples.filter((e) => e.type !== 'video');
        }
        if (!enabledFeatures.searchEngine) {
            examples = examples.filter((e) => e.type !== 'search');
        }
        const numQuestions = examples.length;
        const randomQuestions: multiModalQuestion[] = [];
        const seenQn = new Set<number>();
        const seenType: Record<string, number> = {};
        while (randomQuestions.length < len) {
            const i = Math.floor(Math.random() * numQuestions);
            const type = examples[i]['type'];
            if (!seenQn.has(i) && (seenType[type] ?? 0) < repeat) {
                randomQuestions.push(examples[i]);
                seenQn.add(i);
                seenType[type] ??= 0;
                seenType[type]++;
            }
        }
        return randomQuestions;
    }, [len, enabledFeatures.audioUpload, enabledFeatures.videoUpload, enabledFeatures.searchEngine]);
};
