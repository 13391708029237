import { useDispatch, useSelector } from 'react-redux';

import { resetRequestOption } from '@/store/slices/request-option-slice';

import {
    addMessageToChatHistory,
    addResendMessageToChatHistory,
    clearChatHistory,
    createChatResponse,
    createNewChatHistory,
    setChatHistory,
    setSystemConvos,
    updateChatHistory,
    updateChatHistoryFeedBack,
    updateChatLoading,
    updateChatResponse,
    updateChatSettings,
    updateChatTitle,
} from '../slices/chat-history-slice';
import { RootState, store } from '../store';

export const useChatHistoryStore = () => {
    const dispatch = useDispatch();
    const chatHistory = useSelector((state: RootState) => state.chatHistory.chatHistory);
    const systemConvos = useSelector((state: RootState) => state.chatHistory.systemConvos);
    function withDispatch<F extends (...args: any[]) => any>(fn: F) {
        return (...payload: Parameters<F>) => dispatch(fn(...payload));
    }

    const updateChatLoadingAction = withDispatch(updateChatLoading);
    const updateChatResponseAction = withDispatch(updateChatResponse);
    const createChatResponseAction = withDispatch(createChatResponse);
    const updateChatTitleAction = withDispatch(updateChatTitle);
    const setChatHistoryAction = withDispatch(setChatHistory);
    const updateChatHistoryAction = withDispatch(updateChatHistory);
    const updateChatSettingsAction = withDispatch(updateChatSettings);
    const addMessageToChatHistoryAction = withDispatch(addMessageToChatHistory);
    const addResendMessageToChatHistoryAction = withDispatch(addResendMessageToChatHistory);
    const createNewChatHistoryAction = withDispatch(createNewChatHistory);
    const setUpdateChatHistoryFeedBackActionAction = withDispatch(updateChatHistoryFeedBack);
    const setSystemConvosAction = withDispatch(setSystemConvos);
    const clearChatHistoryAction = () => {
        dispatch(clearChatHistory());
    };

    const newChatAction = () => {
        dispatch(setSystemConvos([]));
        dispatch(resetRequestOption());
    };

    const getState = () => {
        return store.getState().chatHistory;
    };

    return {
        getState,
        updateChatTitleAction,
        setChatHistoryAction,
        setSystemConvosAction,
        newChatAction,
        chatHistory,
        systemConvos,
        updateChatHistoryAction,
        updateChatSettingsAction,
        addMessageToChatHistoryAction,
        createNewChatHistoryAction,
        clearChatHistoryAction,
        addResendMessageToChatHistoryAction,
        setUpdateChatHistoryFeedBackActionAction,
        updateChatResponseAction,
        createChatResponseAction,
        updateChatLoadingAction,
    };
};
