import { useDispatch, useSelector } from 'react-redux';

import { errorMessageType } from '@/utils/types';

import { setErrorMessage } from '../slices/error-message-slice';
import { RootState } from '../store';

export const useErrorMessageStore = () => {
    const dispatch = useDispatch();
    const errorMessage = useSelector((state: RootState) => state.errorMessage.errorMessage);

    const setErrorMessageAction = (payload: errorMessageType) => {
        dispatch(setErrorMessage(payload));
    };

    return {
        errorMessage,
        setErrorMessageAction,
    };
};
