let webcrypto: Crypto;
if (typeof window === 'undefined') {
    webcrypto = require('crypto').webcrypto;
} else {
    webcrypto = window.crypto;
}
async function generateKey(secret: string) {
    const enc = new TextEncoder();
    const keyMaterial = enc.encode(secret);
    return webcrypto.subtle.importKey('raw', keyMaterial, { name: 'PBKDF2' }, false, ['deriveKey']);
}

async function deriveKey(secret: string) {
    const keyMaterial = await generateKey(secret);
    return webcrypto.subtle.deriveKey(
        {
            name: 'PBKDF2',
            salt: new Uint8Array(16), // It is important to use a unique salt in production
            iterations: 100000,
            hash: 'SHA-256',
        },
        keyMaterial,
        {
            name: 'AES-GCM',
            length: 256,
        },
        true,
        ['encrypt', 'decrypt'],
    );
}

export async function encryptJson(jsonData: any, secret: string) {
    const iv = webcrypto.getRandomValues(new Uint8Array(12)); // Initialization vector
    const key = await deriveKey(secret);
    const enc = new TextEncoder();
    const encoded = enc.encode(JSON.stringify(jsonData));

    const ciphertext = await webcrypto.subtle.encrypt(
        {
            name: 'AES-GCM',
            iv: iv,
        },
        key,
        encoded,
    );

    return encodeToBase64(
        JSON.stringify({
            iv: Array.from(iv),
            data: Array.from(new Uint8Array(ciphertext)),
        }),
    );
}

export async function decryptJson(encryptedData: string, secret: string) {
    const { iv, data } = JSON.parse(decodeFromBase64(encryptedData));
    const key = await deriveKey(secret);
    const ciphertext = new Uint8Array(data);

    const decrypted = await webcrypto.subtle.decrypt(
        {
            name: 'AES-GCM',
            iv: new Uint8Array(iv),
        },
        key,
        ciphertext,
    );

    const dec = new TextDecoder();
    return JSON.parse(dec.decode(decrypted));
}

function decodeFromBase64(base64Str: string) {
    const bytes = Uint8Array.from(atob(base64Str), (c) => c.charCodeAt(0));
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
}

function encodeToBase64(str: string) {
    const encoder = new TextEncoder();
    const bytes = encoder.encode(str);
    // @ts-ignore
    return btoa(String.fromCharCode(...bytes));
}
