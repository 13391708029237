import cx from 'classnames';
import React, { memo, useState } from 'react';
import { BiPencil, BiUpload } from 'react-icons/bi';
import { BsFilm, BsImage } from 'react-icons/bs';
import { FiMoreVertical, FiTrash2 } from 'react-icons/fi';
import { MdOutlineIosShare } from 'react-icons/md';

import { useChatId } from '@/hooks/use-chat-id';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { Chat, Message } from '@/utils/types';
import { Link } from '@chakra-ui/next-js';
import { Box, Flex, Heading, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';

import cl from './chat-list-item.module.css';
import { EditableTitle } from './editable-title';

function ListItem_({
    id,
    title,
    loading,
    active,
    media_type,
    handleDeleteItem,
    updateTitle,
    getChatContent,
    handleShareOpen,
}: {
    id: string;
    title: string;
    loading: boolean;
    active: boolean;
    media_type?: string;
    handleDeleteItem: (chatId: string) => void;
    handleShareOpen: (chatId: string) => void;
    getChatContent: (chatId: string) => Message[] | undefined;
    updateTitle: (chatId: string, title: string) => void;
}) {
    const [selected, setSelected] = useState(false);
    const {
        enabledFeatures: { exportChat },
    } = useAppConfig();
    const { noFirebase } = useAuthStore();

    const handleExportChat = () => {
        const content = getChatContent(id);
        if (!content) return;
        navigator.clipboard.writeText(JSON.stringify(content));
    };

    return (
        <Flex
            as={'li'}
            height={'40px'}
            w={'100%'}
            borderRadius={'6px'}
            cursor={'pointer'}
            justifyContent={'space-between'}
            alignItems={'center'}
            className={cx(cl.lineContainer, { [cl.active]: active, [cl.visible]: loading })}
            marginBottom={'4px'}
        >
            {!selected ? (
                <Link
                    style={{ textDecoration: 'none' }}
                    px={'12px'}
                    height={'100%'}
                    display="flex"
                    alignItems={'center'}
                    flex={1}
                    href={`/chat/${id}`}
                >
                    {media_type === 'image' && (
                        <Box as={'span'} mr={'8px'}>
                            {' '}
                            <BsImage />
                        </Box>
                    )}
                    {media_type === 'video' && (
                        <Box as={'span'} mr={'12px'}>
                            {' '}
                            <BsFilm />
                        </Box>
                    )}
                    <Text wordBreak={'break-all'} textStyle={'body-16'} noOfLines={1} color={'text-primary'}>
                        {title}
                    </Text>
                </Link>
            ) : (
                <Box w={'100%'}>
                    <EditableTitle
                        title={title}
                        onClose={() => setSelected(false)}
                        handleSave={updateTitle}
                        chatId={id}
                    />
                </Box>
            )}
            {(!selected || loading) && (
                <Menu boundary={'scrollParent'}>
                    <MenuButton
                        as={IconButton}
                        isLoading={loading}
                        isRound
                        variant={'ghost'}
                        className={cl.lineMenu}
                        icon={<FiMoreVertical />}
                        size={'sm'}
                        fontSize={'18px'}
                        aria-label={`Actions for chat titled: ${title}`}
                        margin={'4px'}
                        _hover={{ background: active ? 'rgba(129, 81, 188, 0.25)' : 'hover-main' }}
                        _active={{ background: active ? 'rgba(129, 81, 188, 0.25)' : 'hover-main' }}
                    />
                    <MenuList>
                        {/*<MenuItem icon={<FiShare />}>Share</MenuItem>*/}
                        {!noFirebase && exportChat && (
                            <MenuItem icon={<BiUpload />} onClick={handleExportChat}>
                                Export chat
                            </MenuItem>
                        )}
                        {!noFirebase && (
                            <MenuItem icon={<MdOutlineIosShare />} onClick={() => handleShareOpen(id)}>
                                Share chat
                            </MenuItem>
                        )}
                        <MenuItem icon={<BiPencil />} onClick={() => setSelected(true)}>
                            Edit title
                        </MenuItem>
                        <MenuItem icon={<FiTrash2 />} onClick={() => handleDeleteItem(id)}>
                            Delete
                        </MenuItem>
                    </MenuList>
                </Menu>
            )}
        </Flex>
    );
}

const ListItem = memo(ListItem_);
export function ChatListItems_({
    chats,
    updateTitle,
    handleDeleteItem,
    getChatContent,
    handleShareOpen,
}: {
    chats: Chat[];
    handleDeleteItem: (chatId: string) => void;
    handleShareOpen: (chatId: string) => void;
    getChatContent: (chatId: string) => Message[] | undefined;
    updateTitle: (chatId: string, title: string) => void;
}) {
    const groupedChats = groupChats(chats);
    const currentItemId = useChatId();
    return (
        <Box flex={1} minHeight={0} overflowY={'scroll'} overflowX={'hidden'} className={cl.customScroll}>
            {Object.entries(groupedChats).map(
                ([label, chatsSubList]) =>
                    !!chatsSubList.length && (
                        <Flex key={label} flexDirection={'column'} w={'100%'} mb={'20px'}>
                            <Heading
                                fontSize={'14px'}
                                fontWeight={500}
                                fontFamily={'monospace'}
                                textTransform={'uppercase'}
                                as={'h3'}
                                paddingTop={'4px'}
                                paddingBottom={'8px'}
                                color={'header-secondary'}
                                width={'100%'}
                            >
                                {label}
                            </Heading>
                            <ol>
                                {chatsSubList.map((item) => {
                                    const active = item.id === currentItemId;
                                    return (
                                        <ListItem
                                            active={active}
                                            key={item.id}
                                            id={item.id}
                                            title={item.title}
                                            loading={!!item.loading}
                                            media_type={item.history[0]?.media_type}
                                            updateTitle={updateTitle}
                                            handleDeleteItem={handleDeleteItem}
                                            getChatContent={getChatContent}
                                            handleShareOpen={handleShareOpen}
                                        />
                                    );
                                })}
                            </ol>
                        </Flex>
                    ),
            )}
        </Box>
    );
}

export const ChatListItems = memo(ChatListItems_, (a, b) => {
    if (a.chats.length !== b.chats.length) return false;
    if (a.updateTitle !== b.updateTitle) return false;
    if (a.handleDeleteItem !== b.handleDeleteItem) return false;
    if (a.getChatContent !== b.getChatContent) return false;
    if (a.handleShareOpen !== b.handleShareOpen) return false;

    for (let i = 0; i < a.chats.length; i++) {
        const chat = a.chats[i];
        if (chat.id !== b.chats[i]?.id) return false;
        if (chat.loading !== b.chats[i]?.loading) return false;
        if (chat.title !== b.chats[i]?.title) return false;
    }

    return true;
});

const hour = 1000 * 60 * 60;
const currentTime = Date.now();
const labels = ['Today', 'Last 14 days', 'Last 30 days', 'Older than 30 days'];
const timePeriods = [currentTime - 24 * hour, currentTime - 14 * 24 * hour, currentTime - 30 * 24 * hour];
function groupChats(chats: Chat[]) {
    // sort from early to late (big to small)
    chats = [...chats].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    let curr = 0;
    let prev = 0;
    const groups = timePeriods.map((period) => {
        // while curr time is smaller than time period
        prev = curr;
        while (chats[curr] && period < chats[curr].createdAt.getTime()) {
            curr++;
        }
        return chats.slice(prev, curr);
    });
    groups.push(chats.slice(curr));

    return labels.reduce<Record<string, Chat[]>>((hash, label, currentIndex) => {
        hash[label] = groups[currentIndex];
        return hash;
    }, {});
}
