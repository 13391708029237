import cx from 'classnames';
import { md5 } from 'js-md5';
import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';

import { ChatMetadata } from '@/api/firestore';
import cl from '@/components/components.module.css';
import { FeedbackModal } from '@/components/feedback-modal';
import { ShareChatModal } from '@/components/share-chat-modal';
import { useChatControls } from '@/contexts/chat-controls';
import { useChatId } from '@/hooks/use-chat-id';
import { useResendMessage } from '@/hooks/use-resend-message';
import { useSendMessage } from '@/hooks/use-send-message';
import { useStickyScroll } from '@/hooks/use-sticky-scroll';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useErrorMessageStore } from '@/store/hooks/use-error-message-store';
import { Chat } from '@/utils/types';
import { AbsoluteCenter, Box, Divider, Flex, useDisclosure } from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';

import { ErrorMessageContainer } from './error-message-container';
import { HumanMessageContainer, ModelMessageContainer } from './message-container';

export function ChatIdMessages({ item }: { item: Chat }) {
    const id = useChatId()!;
    const ref = useRef(new Set());

    const { requestOption } = useChatControls();
    const smoothStream = !!requestOption.persona;

    const { handleReSend } = useResendMessage(id);
    const { stopGeneration } = useSendMessage();
    const { errorMessage } = useErrorMessageStore();
    const {
        enabledFeatures: { showSystemPrompts },
    } = useAppConfig();
    const router = useRouter();
    const { appUser } = useAuthStore();

    const { isSticking, scrollContainerRef } = useStickyScroll(item);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();
    const [selectedMessage, setSelected] = useState<number | null>(null);

    const openFeedbackModal = (messageIndex: number) => () => {
        onOpen();
        setSelected(messageIndex);
    };

    const closeFeedbackModal = () => {
        onClose();
        setSelected(null);
    };

    const openShareModal = (messageIndex: number) => () => {
        onShareOpen();
        setSelected(messageIndex);
    };

    const closeShareModal = () => {
        onShareClose();
        setSelected(null);
    };

    if (!item) {
        router.replace('/chat');
        return <div />;
    }

    const currentChatError = errorMessage?.currentId === item?.id;
    const lastResponse = item.history?.at(-1);
    const lastRequestIndex = item.history?.reduce((i, item, index) => (item.type === 'human' ? index : i), 0);
    let systemMessageIdOffset = 0;

    if (!showSystemPrompts && item.history) {
        const firstNonSystemRoundIndex = item.history.findIndex((convo) => !convo.system);
        systemMessageIdOffset = firstNonSystemRoundIndex;
        if (firstNonSystemRoundIndex > 0) {
            const firstSystemRound = item.history[0];
            item = {
                ...item,
                history: [
                    {
                        ...item.history[firstNonSystemRoundIndex],
                        image_url: firstSystemRound.image_url,
                        media_type: firstSystemRound.media_type,
                    },
                    ...item.history.slice(firstNonSystemRoundIndex + 1),
                ],
            };
        }
    }

    const createCodeCopyHandler = (index: number) => (code: string) => {
        try {
            if (!appUser?.uid) return;
            const codeHash = md5.create().update(code).hex();
            if (ref.current.has(`${index}|${codeHash}`)) return;
            ref.current.add(`${index}|${codeHash}`);
            ChatMetadata.registerEvent(
                appUser?.uid,
                id,
                { name: 'copy_code_block', content: code, messageIndex: index },
                item.history.slice(0, index + 1),
            );
        } catch (e) {
            captureException(e);
        }
    };

    return (
        <Flex h={'100%'} w={'100%'} justifyContent={'flex-end'} flexDir={'column'}>
            <Box id={'message-list'} key={item.id} overflowY={'auto'} ref={scrollContainerRef} className={cl.messages}>
                {item.history?.map((message, index) => {
                    const isLastResponse = message === lastResponse;
                    const prev = item.history?.[index - 1]!;
                    return (
                        <>
                            <Box key={index}>
                                {message.type === 'human' && (
                                    <HumanMessageContainer
                                        message={message.text as string}
                                        type={'human'}
                                        image={message?.image_url}
                                        mediaType={message?.media_type}
                                        messageIndex={systemMessageIdOffset + index}
                                        handleReSend={handleReSend}
                                        isLoading={item.loading}
                                    />
                                )}
                                {message.type === 'model' && message.finish_reason !== 'max-tokens-reached' && (
                                    <ModelMessageContainer
                                        chat={item}
                                        message={message.text as string}
                                        useSearchEngine={prev?.use_search_engine}
                                        sources={message.retrieved_chunks}
                                        responseText={message?.text as string}
                                        type={'model'}
                                        handleReSend={handleReSend}
                                        smoothStream={smoothStream}
                                        isLoading={item.loading && isLastResponse}
                                        messageIndex={systemMessageIdOffset + index}
                                        currentMessage={item.history}
                                        thumbs_up={message?.thumbs_up}
                                        finish_reason={message?.finish_reason}
                                        stopGeneration={() => stopGeneration(id)}
                                        handleCodeCopy={createCodeCopyHandler(index)}
                                        showFeedback={openFeedbackModal(index)}
                                        showShareModal={openShareModal(index)}
                                    />
                                )}
                            </Box>
                            {(message.clear_context || message.finish_reason === 'max-tokens-reached') && (
                                <Box fontSize={'16px'} position="relative" padding="8">
                                    <Divider borderColor={'border-alt'} />
                                    <AbsoluteCenter background={'background-main'} px="6">
                                        {message.clear_context &&
                                            message.finish_reason !== 'max-tokens-reached' &&
                                            'Context cleared'}
                                        {message.finish_reason === 'max-tokens-reached' && 'Context length limit'}
                                    </AbsoluteCenter>
                                </Box>
                            )}
                        </>
                    );
                })}
                {!!errorMessage?.message && currentChatError && (
                    <ErrorMessageContainer
                        errorMessage={errorMessage?.message}
                        handleRetry={() => handleReSend(lastRequestIndex)}
                    />
                )}
            </Box>
            <FeedbackModal
                onClose={closeFeedbackModal}
                isOpen={isOpen}
                chatId={item.id}
                history={item.history}
                messageIndex={selectedMessage!}
            />
            {isShareOpen && (
                <ShareChatModal
                    messageIndex={selectedMessage!}
                    chatId={item.id}
                    isOpen={isShareOpen}
                    onClose={closeShareModal}
                />
            )}
        </Flex>
    );
}
