import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ChatResource } from '@/api/firestore';
import { useOnReady } from '@/contexts/user-authentication';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { clearChatHistory } from '@/store/slices/chat-history-slice';
import { chatSelectors, chatsSlice } from '@/store/slices/chats-slice';
import { resetRequestOption } from '@/store/slices/request-option-slice';
import { Chat, Message } from '@/utils/types';
import { useUser } from '@auth0/nextjs-auth0/client';
import { captureException, captureMessage } from '@sentry/nextjs';

import { RootState, store } from '../store';

export const useChats = () => {
    const { appUser } = useAuthStore();
    const UID = appUser?.uid || ''; // UID should be present
    const dispatch = useDispatch();

    const chats = useSelector(chatSelectors.selectAll);

    const getState = () => {
        return chatSelectors.selectAll(store.getState());
    };

    async function migrateChatHistory() {
        const chatHistory = store.getState().chatHistory;
        try {
            for (let item of chatHistory.chatHistory) {
                createChat({ ...item, history: item.convos });
                await new Promise((resolve) => setTimeout(resolve, 300));
            }
            dispatch(clearChatHistory());
        } catch (e) {
            captureMessage('Migration failed', 'error');
            captureException(e);
        }
    }

    function createChat(chat: Partial<Chat>): string {
        const newChat = ChatResource.create(UID, chat);
        dispatch(chatsSlice.actions.createChat(newChat));
        return newChat.id;
    }

    function saveChat(chatId: string) {
        let chat = chatSelectors.selectById(store.getState(), chatId);
        if (!chat) return;
        ChatResource.update(UID, chat.id, chat);
    }

    function updateChat(id: string, chat: Partial<Chat>) {
        dispatch(chatsSlice.actions.updateChat({ id, changes: chat }));
        ChatResource.update(UID, id, chat);
    }

    function updateChatLoading(id: string, loading: boolean) {
        dispatch(chatsSlice.actions.updateChat({ id, changes: { loading } }));
    }

    function updateChatMessage(id: string, messageIndex: number, message: Partial<Message>) {
        dispatch(chatsSlice.actions.updateChatResponse({ chatId: id, messageIndex, message }));
    }

    function clearChatContext(id: string) {
        dispatch(chatsSlice.actions.clearChatContext({ chatId: id }));
    }

    function addChatMessage(id: string, message: Message) {
        dispatch(chatsSlice.actions.addMessageToChatHistory({ chatId: id, message }));
    }

    function deleteChat(id: string) {
        dispatch(chatsSlice.actions.deleteChat(id));
        ChatResource.delete(UID!, id);
    }

    function deleteAll() {
        const chats = getState();
        dispatch(chatsSlice.actions.deleteAll());
        ChatResource.deleteAll(
            UID!,
            chats.map(({ id }) => id),
        );
    }

    function newChatAction() {
        dispatch(resetRequestOption());
    }

    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
        // @ts-ignore
        window.importChat = (history: Message[], data: Partial<Chat>) =>
            createChat({
                title: 'Manually imported conversation',
                history,
                ...data,
            });
    }

    return {
        getState: useCallback(getState, []),
        chats,
        createChat: useCallback(createChat, [dispatch, UID]),
        updateChatLoading: useCallback(updateChatLoading, [dispatch]),
        updateChat: useCallback(updateChat, [dispatch, UID]),
        saveChat: useCallback(saveChat, [dispatch, UID]),
        deleteChat: useCallback(deleteChat, [dispatch, UID]),
        deleteAll: useCallback(deleteAll, [dispatch, UID]),
        updateChatMessage: useCallback(updateChatMessage, [dispatch]),
        addChatMessage: useCallback(addChatMessage, [dispatch]),
        newChatAction: useCallback(newChatAction, [dispatch]),
        migrateChatHistory: useCallback(migrateChatHistory, [dispatch]),
        clearChatContext: useCallback(clearChatContext, [dispatch]),
    };
};

export function useFetchAllChats() {
    const { appUser } = useAuthStore();
    const { user } = useUser();
    const UID = appUser?.uid || ''; // UID should be present
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const chats = useSelector(chatSelectors.selectAll);
    useOnReady(() => {
        (async () => {
            const { data } = await ChatResource.fetchAll(UID);
            dispatch(chatsSlice.actions.fetchChats(data));
            setLoading(false);
        })();
    }, []);
    return { chats, isLoading };
}

export function useFetchChat(id: string) {
    const { appUser } = useAuthStore();
    const UID = appUser?.uid || ''; // UID should be present
    const dispatch = useDispatch();
    const chat: Chat | undefined = useSelector<RootState, Chat | undefined>((state) =>
        chatSelectors.selectById(state, id),
    );
    const [isLoading, setLoading] = useState(id && !chat);
    const [hasError, setError] = useState<string | null>(null);
    useOnReady(() => {
        if (chat || !id) return;
        (async () => {
            setLoading(true);
            setError(null);
            const { data, err } = await ChatResource.fetch(UID!, id);

            if (!data || err) {
                setError(err);
            } else {
                dispatch(chatsSlice.actions.fetchChats([data]));
            }
            setLoading(false);
        })();
    }, [id]);
    return { chat, isLoading, hasError };
}
