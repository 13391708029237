import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

export function RightPanelContainer({ children }: { children: React.ReactNode }) {
    return (
        <Box w={['100%', '100%', '100%', '100%', '70%']}>
            <Flex flexDirection="column" h={'var(--chakra-vh)'} w={['100%', '100%', '100%', '100%', '80%']}>
                {children}
            </Flex>
        </Box>
    );
}
