import React, { useEffect, useRef, useState } from 'react';
import { BsLink45Deg } from 'react-icons/bs';

import { SharedChatResource } from '@/api/firestore';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useChats } from '@/store/hooks/use-chat-store';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { useSharedChat } from '@/store/hooks/use-shared-chat';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/lib/query-keys';
import { getSharedChatId } from '@/utils/share-chat';

interface ShareChatModalProps {
    messageIndex: number;
    isOpen: boolean;
    onClose: () => void;
    chatId: string;
}

export function ShareChatModal({ isOpen, onClose, chatId, messageIndex }: ShareChatModalProps) {
    const queryClient = useQueryClient();
    const { appUser } = useAuthStore();
    const ref = useRef(null);
    const { chats, updateChat } = useChats();
    const chat = chats.find((c) => c.id === chatId);
    const sharedChatId = chat ? getSharedChatId(chat, messageIndex) : undefined;
    const [title, setTitle] = useState(chat?.title);
    const { data: sharedChat, isLoading: loadingSharedChat } = useSharedChat({
        id: sharedChatId ?? '',
    })
    const [displayCopied, setDisplayCopied] = useState(false);
    const [error, setError] = useState('');
    const isShared = !!sharedChatId && !loadingSharedChat && !!sharedChat

    useEffect(() => {
        if (!sharedChatId) return;
        setTitle(chat?.title);
        setDisplayCopied(false);
    }, [sharedChatId, chat?.title]);

    const handleCopy = () => {
        navigator.clipboard.writeText(`${location.origin}/share/${sharedChatId}`);
        if (displayCopied) return;
        setDisplayCopied(true);
        setTimeout(() => {
            setDisplayCopied(false);
        }, 2000);
    };

    const handleShareCreate = () => {
        if (isShared || !sharedChatId) return;

        setError('');
        if (!title || title.length < 3) {
            setError('Chat title invalid');
            return;
        }
        updateChat(chatId, { sharedAt: new Date() });
        if (!chat) {
            setError('Unable to share chat');
            return;
        }

        /**
         * This is for supporting backwards compatibility.
         * In the future, sharing a link without an index into messages
         * will share the entire chat history live.
         */
        const sharedMessages = messageIndex > 0 ? chat.history.slice(0, messageIndex + 1) : chat.history;

        SharedChatResource.create(appUser?.uid!, {
            history: sharedMessages,
            title,
            id: sharedChatId,
            chatId,
            modelName: chat.modelName,
        });
        void queryClient.invalidateQueries({
            queryKey: QueryKeys.chat.getSharedChat(sharedChatId),
        })
        handleCopy();
    };

    return (
        <Modal size={'lg'} initialFocusRef={ref} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={'28px'} fontWeight={'400'}>
                    Share Chat
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={'32px'}>
                        This will create a link to the conversation you are sharing. Messages you send after creating
                        your link won’t be shared. Anyone with the URL will be able to view the shared chat.
                    </Text>
                    <FormControl isInvalid={!!error}>
                        <FormLabel>Chat title</FormLabel>
                        <Textarea
                            fontSize={'18px'}
                            fontWeight={'500'}
                            isRequired
                            color={'text-primary'}
                            minLength={3}
                            maxLength={128}
                            mb={'8px'}
                            readOnly={isShared}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        ></Textarea>
                        <FormErrorMessage>{error}</FormErrorMessage>
                    </FormControl>
                    <Text mb={'8px'} fontSize={'14px'} color={'text-subtle'} fontWeight={'500'}>
                        {sharedChat?.createdAt ? 'Shared on: ' : ''}
                        {sharedChat?.createdAt ? sharedChat.createdAt?.toDateString() : new Date().toDateString()}
                    </Text>
                    {sharedChat && (
                        <InputGroup background={'whiteAlpha.200'} pointerEvents="none">
                            <InputLeftElement>
                                <BsLink45Deg fontSize={'22px'} />
                            </InputLeftElement>
                            <Input type="text" value={`${location.origin}/share/${sharedChatId}`} readOnly />
                        </InputGroup>
                    )}
                </ModalBody>

                <ModalFooter>
                    <Button width={'auto'} onClick={isShared ? handleCopy : handleShareCreate} variant="darkPurple" disabled={!!sharedChatId && !loadingSharedChat}>
                        {isShared ? (displayCopied ? 'Link copied' : 'Copy link') : 'Create shareable link'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

ShareChatModal.displayName = "ShareChatModal"
