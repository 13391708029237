import { useDispatch, useSelector } from 'react-redux';

import { FileResource } from '@/api/firestore';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { UploadedFile, uploadedFilesAdapter, uploadedFilesSlice } from '@/store/slices/uploaded-files-slice';

import { RootState } from '../store';

export const useUploadedFiles = () => {
    const { appUser, noFirebase } = useAuthStore();
    const dispatch = useDispatch();
    const selectors = uploadedFilesAdapter.getSelectors<RootState>((state) => state.uploadedFiles);

    const uploadedFiles = useSelector(selectors.selectAll);
    const urls = useSelector(selectors.selectIds);

    function touchFile(url: string) {
        if (urls.includes(url)) {
            const lastUsed = new Date().toISOString();
            dispatch(
                uploadedFilesSlice.actions.updateFile({
                    id: url,
                    changes: {
                        lastUsed,
                    },
                }),
            );
            const file = uploadedFiles.find((file) => file.url === url);
            if (file) FileResource.updateFile(appUser!.uid, file.id, { lastUsed });
        }
    }

    function addFile(file: Omit<UploadedFile, 'createdAt' | 'lastUsed' | 'id'>) {
        if (uploadedFiles.length === 18) {
            const lastFile = uploadedFiles.at(-1);
            if (lastFile) {
                dispatch(uploadedFilesSlice.actions.removeFile(lastFile.url));
                FileResource.removeFile(appUser!.uid, lastFile.id);
            }
        }
        if (noFirebase) {
            return;
        }
        const newFile = {
            ...file,
            createdAt: new Date().toISOString(),
            lastUsed: new Date().toISOString(),
        };
        const id = FileResource.addFile(appUser!.uid, newFile);
        dispatch(uploadedFilesSlice.actions.saveFile({ ...newFile, id }));
    }

    async function fetchFiles() {
        if (!appUser?.uid) return;
        const { data } = await FileResource.fetchAll(appUser.uid);
        if (data) {
            dispatch(uploadedFilesSlice.actions.fetchFiles(data));
        }
        return data;
    }

    return {
        files: uploadedFiles,
        addFile,
        touchFile,
        fetchFiles,
        removeAll: () => dispatch(uploadedFilesSlice.actions.removeAll()),
    };
};
