import { useEffect, useState } from 'react';

import { PersonaEntry } from '@/api/reka';
import { configValue } from '@/config/config-value';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { Box, FormLabel, Input, Select, Textarea } from '@chakra-ui/react';

interface Voice {
    name: string;
    voice_id: string;
}

const PersonaEntryEditor = ({
    value,
    update,
    del,
    availableVoices,
}: {
    value: PersonaEntry;
    update?: (value: PersonaEntry) => void;
    del?: () => void;
    availableVoices: Voice[];
}) => {
    return (
        <Box width={'calc(100% - 60px)'} paddingY={'24px'} minW={'min(300px, 100%)'}>
            <div>
                <FormLabel>persona name:</FormLabel>
                <Input
                    type="text"
                    style={{ width: '100%' }}
                    value={value.name}
                    disabled={!update}
                    onChange={(e) =>
                        update &&
                        update({
                            ...value,
                            name: e.target.value,
                        })
                    }
                ></Input>
            </div>

            <FormLabel>system prompt:</FormLabel>
            <Textarea
                style={{ width: '100%' }}
                value={value.persona.systemPrompt}
                disabled={!update}
                onChange={(e) =>
                    update &&
                    update({
                        ...value,
                        persona: {
                            ...value.persona,
                            systemPrompt: e.target.value,
                        },
                    })
                }
            ></Textarea>
            <FormLabel>assistant begin text:</FormLabel>
            <Input
                type="text"
                style={{ width: '100%' }}
                value={value.persona.assistantStartText}
                disabled={!update}
                onChange={(e) =>
                    update &&
                    update({
                        ...value,
                        persona: {
                            ...value.persona,
                            assistantStartText: e.target.value,
                        },
                    })
                }
            ></Input>
            <FormLabel>search engine keyword:</FormLabel>
            <Input
                type="text"
                style={{ width: '100%' }}
                value={value.persona.searchKeywords}
                disabled={!update}
                onChange={(e) =>
                    update &&
                    update({
                        ...value,
                        persona: {
                            ...value.persona,
                            searchKeywords: e.target.value,
                        },
                    })
                }
            ></Input>
            <FormLabel>voiceId:</FormLabel>
            <Select
                value={value.persona.voiceId}
                style={{ backgroundColor: 'ffff14' }}
                disabled={!update}
                onChange={(e) => {
                    if (update) {
                        console.log({ UPDATING_VOICE: e.target.value });
                        update({
                            ...value,
                            persona: {
                                ...value.persona,
                                voiceId: e.target.value,
                            },
                        });
                    }
                }}
            >
                {availableVoices.map((v) => (
                    <option key={v.voice_id} value={v.voice_id}>
                        {v.name}
                    </option>
                ))}
            </Select>
            {del && <button onClick={del}>Remove</button>}
        </Box>
    );
};

export const PersonaConfigForm = () => {
    const { systemPersonas, customPersonas, setCustomPersonasAction } = useAppConfig();
    const [availableVoices, setAvailableVoices] = useState([] as Voice[]);
    useEffect(() => {
        (async () => {
            const results = await (
                await fetch('https://api.elevenlabs.io/v1/voices', {
                    headers: {
                        accept: 'application/json',
                        'xi-api-key': configValue.elevenlabsKey!,
                    },
                })
            ).json();
            setAvailableVoices(results.voices.map((v: any) => ({ name: v.name, voice_id: v.voice_id })));
        })();
    }, []);

    if (availableVoices.length === 0) {
        return <>Loading</>;
    }

    return (
        <>
            {systemPersonas.map((entry) => (
                <PersonaEntryEditor
                    key={'system' + entry.name}
                    value={entry}
                    availableVoices={availableVoices}
                ></PersonaEntryEditor>
            ))}
            {customPersonas.map((entry, i) => (
                <PersonaEntryEditor
                    key={'user-' + i}
                    value={entry}
                    update={(value) => {
                        setCustomPersonasAction([
                            ...customPersonas.slice(0, i),
                            value,
                            ...customPersonas.slice(i + 1, customPersonas.length),
                        ]);
                    }}
                    del={() => {
                        setCustomPersonasAction([
                            ...customPersonas.slice(0, i),
                            ...customPersonas.slice(i + 1, customPersonas.length),
                        ]);
                    }}
                    availableVoices={availableVoices}
                ></PersonaEntryEditor>
            ))}
            <button
                onClick={() =>
                    setCustomPersonasAction([
                        ...customPersonas,
                        {
                            name: `random-man-${Math.random()}`,
                            persona: {
                                systemPrompt: 'Act as a knight',
                                assistantStartText: 'unknown challenger:',
                                voiceId: '',
                            },
                        },
                    ])
                }
            >
                Add Persona
            </button>
        </>
    );
};
