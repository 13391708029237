import React, { useRef } from 'react';

import { ChatMetadata } from '@/api/firestore';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { Message } from '@/utils/types';
import { useUser } from '@auth0/nextjs-auth0/client';
import {
    Button,
    Checkbox,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Textarea,
} from '@chakra-ui/react';
import { captureException, sendFeedback } from '@sentry/nextjs';

export function FeedbackModal({
    isOpen,
    onClose,
    history,
    chatId,
    messageIndex,
}: {
    isOpen: boolean;
    onClose: () => void;
    history: Message[];
    chatId: string;
    messageIndex: number;
}) {
    const { appUser } = useAuthStore();
    const { user } = useUser();
    const ref = useRef(null);
    const handleFeedback = (e: React.FormEvent<HTMLFormElement>) => {
        if (!appUser?.uid) return onClose();
        e.preventDefault();
        try {
            const form = new FormData(e.target as HTMLFormElement);
            const data = {
                feedback: form.get('feedback'),
                unsafe: form.get('unsafe'),
                inaccurate: form.get('inaccurate'),
                unhelpful: form.get('unhelpful'),
            };
            sendFeedback(
                {
                    name: appUser?.uid ?? '',
                    email: user?.email ?? '',
                    message: JSON.stringify({ chatId, data }),
                },
                { includeReplay: true },
            );
            ChatMetadata.registerEvent(
                appUser?.uid,
                chatId,
                {
                    name: 'additional_feedback',
                    response: history[messageIndex].text,
                    messageIndex,
                    ...data,
                },
                history.slice(0, messageIndex + 1),
            );
        } catch (e) {
            captureException(e);
        }
        onClose();
    };
    return (
        <Modal initialFocusRef={ref} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Provide feedback</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleFeedback}>
                    <ModalBody>
                        <Textarea
                            name="feedback"
                            placeholder={'Share your feedback on this response'}
                            ref={ref}
                            mb={'12px'}
                        ></Textarea>
                        <Stack direction="column">
                            <Checkbox name="unsafe" value={'true'} colorScheme={'gray'}>
                                This is unsafe
                            </Checkbox>
                            <Checkbox name="inaccurate" value={'true'} colorScheme={'gray'}>
                                This is inaccurate
                            </Checkbox>
                            <Checkbox name="unhelpful" value={'true'} colorScheme={'gray'}>
                                This isn{"'"}t helpful
                            </Checkbox>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button type={'submit'} width={'auto'} variant={'purpleOutline'}>
                            Submit
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
}
